// DO NOT EDIT!!! This is autogenerated file, any changes made here will be 
// overwritten by the next REST API schema update.

import axios from 'axios';
import { http, makeFormData } from 'lib';
import { DATE_FORMAT } from 'const';
import { format } from 'date-fns/format';

export type ErrorCode = 'not_found' | 'method_not_allowed' | 'internal' | 'unauthorized' | 'expired_token' | 'expired_refresh_token' | 'access_denied' | 'forbidden' | 'no_token' | 'missing_jwt' | 'invalid_token' | 'validation_failed' | 'unprocessable_entity';

export type PageInfo = {
  count: number;
}

export type ApiResponseMeta<TMeta> = {
  pageInfo: PageInfo | null;
  data: TMeta;
}

export type DebugMessage = {
  message: string;
  query: string | null;
}

export type DebugInfo = {
  messages: DebugMessage[];
}

export type ApiResponse<TData, TMeta> = {
  errorCode: ErrorCode | null;
  data: TData;
  meta: ApiResponseMeta<TMeta> | null;
  debug: DebugInfo | null;
  timestamp: Date;
}

export type ApiErrorResponse<TData, TMeta> = {
  message: string | null;
  errorCode: ErrorCode | null;
  data: TData;
  meta: ApiResponseMeta<TMeta> | null;
  debug: DebugInfo | null;
  timestamp: Date;
}

export type ActivityType = 'camp' | 'regular';

export type ActivityStatus = 'expired' | 'active' | 'full' | 'unpublished';

export type MediaView = {
  fileName: string;
  publicUrl: string;
}

export type CoachActivityListView = {
  id: number;
  type: ActivityType;
  status: ActivityStatus;
  name: string;
  sessionCount: number;
  vacancies: number;
  shiftsStart: Date | null;
  shiftsEnd: Date | null;
  publicUrl: string;
  canPublish: boolean;
  hasPublishedSessions: boolean;
  hasAgreement: boolean;
  hasPersonalDataAgreement: boolean;
  photo: MediaView | null;
}

export type ActivityIndexInput = {
  club: number;
}

export type ActivityDropdownOption = {
  id: number;
  name: string;
}

export type ActivityDropdownInput = {
  club: number;
}

export type Entity = {
  id: number;
}

export type AddActivityForm = {
  type: ActivityType;
  name: string;
  shortName: string;
  category: Entity | null;
  description: string;
  productCode: string | null;
}

export type AddActivityFormFiles = {
  photo: File
}

export type ActivityCreateInput = {
  club: number;
  form: AddActivityForm;
  files: AddActivityFormFiles | null;
}

export type ActivityDeleteInput = {
  club: number;
  activity: number;
}

export type CategoryListView = {
  id: number;
  name: string;
  photo: MediaView | null;
}

export type CoachActivityDetailsView = {
  id: number;
  name: string;
  type: ActivityType;
  description: string;
  shortName: string;
  payForLastMonth: boolean;
  productCode: string | null;
  category: CategoryListView | null;
  photo: MediaView | null;
}

export type ActivityShowInput = {
  club: number;
  activity: number;
}

export type UpdateActivityForm = {
  payForLastMonth: boolean;
  name: string;
  shortName: string;
  category: Entity | null;
  description: string;
  productCode: string | null;
}

export type UpdateActivityFormFiles = {
  photo: File
}

export type ActivityUpdateInput = {
  club: number;
  activity: number;
  form: UpdateActivityForm;
  files: UpdateActivityFormFiles | null;
}

export type EditorJSData = {
  time: number;
  blocks: any[];
  version: string;
}

export type TemplateVariable = {
  name: string;
  description: string;
}

export type ActivityEditorTemplateSettings = {
  agreementSeries: string | null;
  lastAgreementNumber: number | null;
  template: EditorJSData | null;
  variables: TemplateVariable[];
}

export type ActivityShowAgreementInput = {
  club: number;
  activity: number;
}

export type ActivityEditorTemplateSettingsForm = {
  agreementSeries: string | null;
  lastAgreementNumber: number | null;
  template: EditorJSData;
}

export type ActivityUpdateAgreementInput = {
  club: number;
  activity: number;
  form: ActivityEditorTemplateSettingsForm;
}

export type EditorTemplateSettings = {
  template: EditorJSData | null;
  variables: TemplateVariable[];
}

export type ActivityShowPersonalDataAgreementInput = {
  club: number;
  activity: number;
}

export type EditorTemplateSettingsForm = {
  template: EditorJSData;
}

export type ActivityUpdatePersonalDataAgreementInput = {
  club: number;
  activity: number;
  form: EditorTemplateSettingsForm;
}

export type ActivityConfirmationEmailSettings = {
  useDefault: boolean;
  template: EditorJSData | null;
  variables: TemplateVariable[];
}

export type ActivityShowConfirmationEmailInput = {
  club: number;
  activity: number;
}

export type ActivityConfirmationEmailSettingsForm = {
  useDefault: boolean;
  template: EditorJSData | null;
}

export type ActivityUpdateConfirmationEmailInput = {
  club: number;
  activity: number;
  form: ActivityConfirmationEmailSettingsForm;
}

export type ActivityAttendantExportInput = {
  club: number;
  activity: number;
}

export type QuantityDiscountView = {
  id: number;
  quantity: number;
  amount: number;
}

export type ActivityQuantityDiscountIndexInput = {
  activity: number;
}

export type QuantityDiscountForm = {
  quantity: number;
  amount: number;
}

export type ActivityQuantityDiscountCreateInput = {
  activity: number;
  form: QuantityDiscountForm;
}

export type ActivityQuantityDiscountShowInput = {
  activity: number;
  discount: number;
}

export type ActivityQuantityDiscountUpdateInput = {
  activity: number;
  discount: number;
  form: QuantityDiscountForm;
}

export type ActivityQuantityDiscountDeleteInput = {
  activity: number;
  discount: number;
}

export type ActivitySessionAttendantExportInput = {
  activity: number;
  session: number;
}

export type CityListView = {
  id: number;
  name: string;
  localName: string;
}

export type ClubListView = {
  id: number;
  name: string;
  webUrl: string | null;
  logo: MediaView | null;
}

export type UserDetailsView = {
  isAdmin: boolean;
  id: number;
  email: string;
  phone: string | null;
  firstName: string | null;
  lastName: string | null;
  fullName: string;
}

export type CoachListView = {
  club: ClubListView;
  id: number;
  name: string;
  user: UserDetailsView;
}

export type ShiftView = {
  id: number;
  name: string;
  start: Date;
  end: Date | null;
  numberOfSessions: number;
}

export type SessionStatus = 'draft' | 'published' | 'expired' | 'full';

export type CoachSessionListView = {
  id: number;
  name: string;
  capacity: number | null;
  numberOfAttendants: number;
  numberOfReservations: number;
  numberOfInvitations: number;
  city: CityListView;
  coach: CoachListView;
  shift: ShiftView;
  price: number;
  status: SessionStatus;
}

export type CoachShiftListView = {
  id: number;
  name: string;
  sessions: CoachSessionListView[];
}

export type ActivitySessionIndexInput = {
  activity: number;
}

export type WeekScheduleListView = {
  weekDay: number;
  startTime: string;
  endTime: string | null;
}

export type SessionPriceView = {
  validUntil: Date;
  price: number;
}

export type SessionDetailsCoachView = {
  id: number;
  user: UserDetailsView;
}

export type CityDetailsView = {
  id: number;
  name: string;
  localName: string;
}

export type CoachSessionDetailsView = {
  id: number;
  name: string;
  capacity: number | null;
  numberOfAttendants: number;
  ageGroupFrom: number | null;
  ageGroupTo: number | null;
  published: boolean;
  price: number;
  address: string | null;
  weekSchedule: WeekScheduleListView[];
  prices: SessionPriceView[];
  shift: ShiftView;
  activity: CoachActivityDetailsView;
  coach: SessionDetailsCoachView;
  city: CityDetailsView;
}

export type ActivitySessionShowInput = {
  activity: number;
  session: number;
}

export type WeekdayScheduleForm = {
  weekday: number;
  startTime: string;
  endTime: string | null;
}

export type SessionPriceForm = {
  validUntil: Date;
  price: number;
}

export type AddSessionForm = {
  coach: Entity;
  shift: Entity | null;
  name: string;
  capacity: number | null;
  ageGroupFrom: number | null;
  ageGroupTo: number | null;
  city: Entity;
  address: string | null;
  weekdaySchedule: WeekdayScheduleForm[];
  price: number;
  published: boolean;
  prices: SessionPriceForm[];
}

export type ActivitySessionCreateInput = {
  activity: number;
  form: AddSessionForm;
}

export type UpdateSessionForm = {
  coach: Entity;
  shift: Entity | null;
  name: string;
  capacity: number | null;
  ageGroupFrom: number | null;
  ageGroupTo: number | null;
  city: Entity;
  address: string | null;
  weekdaySchedule: WeekdayScheduleForm[];
  price: number;
  published: boolean;
  prices: SessionPriceForm[];
}

export type ActivitySessionUpdateInput = {
  activity: number;
  session: number;
  form: UpdateSessionForm;
}

export type ActivitySessionDeleteInput = {
  activity: number;
  session: number;
}

export type SendMessageForm = {
  title: string;
  content: string;
}

export type SendMessageFormFiles = {
  images: File[]
}

export type SessionMessageSent = {
  recipients: number;
}

export type ActivitySessionSendMessageInput = {
  activity: number;
  session: number;
  form: SendMessageForm;
  files: SendMessageFormFiles | null;
}

export type UserListView = {
  id: number;
  email: string;
  phone: string | null;
  firstName: string | null;
  lastName: string | null;
  fullName: string;
}

export type CoachSessionInvitationListView = {
  id: number;
  created: Date;
  user: UserListView;
}

export type ActivitySessionInvitationIndexInput = {
  activity: number;
  session: number;
}

export type SendInvitationForm = {
  emails: string[];
  text: EditorJSData;
}

export type SessionInvitationsSent = {
  invitations: number;
}

export type ActivitySessionInvitationSendInput = {
  activity: number;
  session: number;
  form: SendInvitationForm;
}

export type ParentShiftView = {
  id: number;
  name: string;
  start: Date;
  end: Date | null;
}

export type InvitationSessionActivity = {
  name: string;
  type: ActivityType;
  photo: MediaView | null;
}

export type InvitationSessionView = {
  id: number;
  weekSchedule: WeekScheduleListView[];
  shift: ParentShiftView;
  activity: InvitationSessionActivity;
}

export type AttendantInvitationDetailsView = {
  id: number;
  created: Date;
  link: string;
  session: InvitationSessionView;
}

export type ActivitySessionInvitationResendInput = {
  activity: number;
  session: number;
  invitation: number;
}

export type ActivitySessionInvitationDeleteInput = {
  activity: number;
  session: number;
  invitation: number;
}

export type CoachRegistrationAttendantListView = {
  guardian: UserListView;
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
}

export type CoachAttendantRegistrationListView = {
  id: number;
  attendant: CoachRegistrationAttendantListView;
  registrationDate: Date;
  firstSessionDate: Date | null;
  acceptsMarketing: boolean;
  acceptsPhoto: boolean;
  notes: string | null;
  coachNotes: string | null;
}

export type ActivitySessionRegistrationIndexInput = {
  activity: number;
  session: number;
}

export type CoachAttendantGuardianDetails = {
  id: number;
  fullName: string;
  email: string;
  phone: string | null;
}

export type CoachRegistrationAttendantDetails = {
  id: number;
  fullName: string;
  dateOfBirth: Date;
  guardian: CoachAttendantGuardianDetails;
}

export type PdfFileDetails = {
  id: number;
  fileNameForDownload: string;
}

export type CoachRegistrationOrder = {
  id: number;
}

export type CoachRegistrationOrderAttendant = {
  id: number;
  agreement: PdfFileDetails | null;
  order: CoachRegistrationOrder;
}

export type SessionResultView = {
  id: number;
  text: string;
  created: Date;
}

export type CoachAttendantRegistrationDetails = {
  id: number;
  startDate: Date;
  notes: string | null;
  coachNotes: string | null;
  attendant: CoachRegistrationAttendantDetails;
  orderAttendant: CoachRegistrationOrderAttendant | null;
  results: SessionResultView[];
}

export type ActivitySessionRegistrationShowInput = {
  activity: number;
  session: number;
  registration: number;
}

export type AttendantRegistrationUpdate = {
  notes: string | null;
}

export type ActivitySessionRegistrationUpdateInput = {
  activity: number;
  session: number;
  registration: number;
  form: AttendantRegistrationUpdate;
}

export type ActivitySessionRegistrationDeleteInput = {
  activity: number;
  session: number;
  registration: number;
}

export type ActivitySessionRegistrationChangeSessionInput = {
  activity: number;
  session: number;
  registration: number;
  form: Entity;
}

export type AddSessionResultForm = {
  text: string;
}

export type ActivitySessionRegistrationAddResultInput = {
  activity: number;
  session: number;
  registration: number;
  form: AddSessionResultForm;
}

export type CoachSessionReservationListView = {
  id: number;
  notes: string | null;
  created: Date;
  user: UserListView;
}

export type ActivitySessionReservationIndexInput = {
  activity: number;
  session: number;
}

export type SessionReservationUpdate = {
  notes: string | null;
}

export type ActivitySessionReservationUpdateInput = {
  activity: number;
  session: number;
  reservation: number;
  form: SessionReservationUpdate;
}

export type ActivitySessionReservationDeleteInput = {
  activity: number;
  session: number;
  reservation: number;
}

export type ActivitySessionReservationInviteInput = {
  activity: number;
  session: number;
  reservation: number;
}

export type ActivityShiftIndexInput = {
  activity: number;
}

export type ShiftForm = {
  start: Date;
  end: Date | null;
}

export type ActivityShiftCreateInput = {
  activity: number;
  form: ShiftForm;
}

export type ActivityShiftUpdateInput = {
  activity: number;
  shift: number;
  form: ShiftForm;
}

export type ActivityShiftDeleteInput = {
  activity: number;
  shift: number;
}

export type ClubAbsenceIndexParams = {
  page?: number | null;
  size?: number | null;
  status?: string | null;
}

export type SessionAbsenceStatus = 'submitted' | 'rejected' | 'approved' | 'refunded';

export type AdminSessionAbsenceGuardianListView = {
  fullName: string;
  phone: string | null;
}

export type AdminSessionAbsenceAttendantListView = {
  firstName: string;
  lastName: string;
  fullName: string;
  guardian: AdminSessionAbsenceGuardianListView;
}

export type AdminSessionAbsenceRegistrationListItem = {
  attendant: AdminSessionAbsenceAttendantListView;
}

export type AdminSessionAbsenceListItem = {
  id: number;
  status: SessionAbsenceStatus;
  start: Date;
  end: Date;
  comment: string;
  discount: number | null;
  approvedDiscount: number | null;
  createdAt: Date;
  registration: AdminSessionAbsenceRegistrationListItem;
}

export type ClubAbsenceIndexInput = {
  club: number;
  params: ClubAbsenceIndexParams | null;
}

export type AdminSessionAbsenceCountsByStatus = {
  submitted: number;
  rejected: number;
  approved: number;
  refunded: number;
}

export type ClubAbsenceCountsByStatusInput = {
  club: number;
}

export type AdminApproveSessionAbsences = {
  approve: Entity[];
}

export type AdminApprovedSessions = {
  approved: number;
  skipped: number;
}

export type ClubAbsenceApproveInput = {
  club: number;
  form: AdminApproveSessionAbsences;
}

export type SessionAbsenceRefundMethod = 'invoice' | 'transfer';

export type AdminSessionAbsenceDetails = {
  id: number;
  comment: string;
  adminComment: string | null;
  discount: number | null;
  approvedDiscount: number | null;
  duration: number;
  status: SessionAbsenceStatus;
  refundMethod: SessionAbsenceRefundMethod | null;
  isInvoiceRefundAvailable: boolean;
  isRefunded: boolean;
}

export type ClubAbsenceShowInput = {
  club: number;
  absence: number;
}

export type SessionAbsenceUpdate = {
  status: SessionAbsenceStatus;
  approvedDiscount: number | null;
  refundMethod: SessionAbsenceRefundMethod | null;
  refunded: boolean;
  adminComment: string | null;
}

export type ClubAbsenceUpdateInput = {
  club: number;
  absence: number;
  form: SessionAbsenceUpdate;
}

export type ClubAttendantIndexParams = {
  page?: number | null;
  size?: number | null;
  search?: string | null;
}

export type ClubAttendantListView = {
  id: number;
  fullName: string;
  createdAt: Date | null;
  guardian: UserListView;
  user: UserListView | null;
}

export type ClubAttendantIndexInput = {
  club: number;
  params: ClubAttendantIndexParams | null;
}

export type AttendantDropdownOption = {
  id: number;
  name: string;
}

export type ClubAttendantDropdownInput = {
  club: number;
}

export type ClubAttendantExportInput = {
  club: number;
}

export type ClubAttendantDetails = {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  dateOfBirth: Date;
  createdAt: Date | null;
  guardian: UserDetailsView;
  user: UserDetailsView | null;
}

export type ClubAttendantShowInput = {
  club: number;
  attendant: number;
}

export type AdminAttendantAccount = {
  email: string;
  password: string | null;
  phone: string | null;
}

export type AdminAttendantForm = {
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  user?: AdminAttendantAccount | null;
}

export type ClubAttendantUpdateInput = {
  club: number;
  attendant: number;
  form: AdminAttendantForm;
}

export type ClubAttendantRegistrationActivityListView = {
  id: number;
}

export type ClubAttendantRegistrationSessionListView = {
  id: number;
  name: string;
  activity: ClubAttendantRegistrationActivityListView;
}

export type ClubAttendantRegistrationListView = {
  id: number;
  startDate: Date;
  session: ClubAttendantRegistrationSessionListView;
}

export type ClubAttendantSessionsInput = {
  club: number;
  attendant: number;
}

export type MyClubMember = {
  id: number;
  isCoach: boolean;
  isAdmin: boolean;
}

export type MyClubView = {
  id: number;
  name: string;
  member: MyClubMember;
}

export type ClubDetailsView = {
  description: string | null;
  companyName: string | null;
  address: string | null;
  companyCode: number | null;
  vatPayer: boolean;
  vatCode: string | null;
  invoiceIssuer: string | null;
  invoiceSeries: string | null;
  lastInvoiceNumber: number | null;
  accountNr: string | null;
  bank: string | null;
  id: number;
  name: string;
  webUrl: string | null;
  logo: MediaView | null;
}

export type ClubShowInput = {
  club: number;
}

export type UpdateClubForm = {
  name: string;
  webUrl: string | null;
  description: string | null;
  companyName: string | null;
  address: string | null;
  companyCode: number | null;
  vatPayer: boolean;
  vatCode: string | null;
  invoiceIssuer: string | null;
  invoiceSeries: string | null;
  lastInvoiceNumber: number | null;
  accountNr: string | null;
  bank: string | null;
}

export type UpdateClubFormFiles = {
  logo: File
}

export type ClubUpdateInput = {
  club: number;
  form: UpdateClubForm;
  files: UpdateClubFormFiles | null;
}

export type ClubSessionsParams = {
  s?: string | null;
}

export type SessionDropdownOption = {
  id: number;
  name: string;
}

export type ClubSessionsInput = {
  club: number;
  params: ClubSessionsParams | null;
}

export type CoachBaseView = {
  id: number;
  name: string;
  user: UserDetailsView;
}

export type ClubCoachesInput = {
  club: number;
}

export type ClubDiscountIndexParams = {
  s?: string | null;
}

export type DiscountListView = {
  id: number;
  code: string;
  discountText: string;
  createdAt: Date;
  expiresAt: Date | null;
  quantity: number | null;
  used: number;
  link: string;
  isValid: boolean;
}

export type ClubDiscountIndexInput = {
  club: number;
  params: ClubDiscountIndexParams | null;
}

export type DiscountType = 'percent' | 'absolute';

export type DiscountHistory = {
  usedAt: Date;
  user: UserListView;
}

export type DiscountDetails = {
  id: number;
  code: string;
  validFrom: Date | null;
  expiresAt: Date | null;
  quantity: number | null;
  type: DiscountType;
  amount: number;
  history: DiscountHistory[];
}

export type ClubDiscountShowInput = {
  club: number;
  discount: number;
}

export type DiscountForm = {
  code: string;
  validFrom: Date | null;
  expiresAt: Date | null;
  quantity: number | null;
  type: DiscountType;
  amount: number;
}

export type ClubDiscountCreateInput = {
  club: number;
  form: DiscountForm;
}

export type ClubDiscountUpdateInput = {
  club: number;
  discount: number;
  form: DiscountForm;
}

export type ClubDiscountDeleteInput = {
  club: number;
  discount: number;
}

export type ClubInvoiceIndexParams = {
  page?: number | null;
  size?: number | null;
  session?: number | null;
  activity?: number | null;
  search?: string | null;
  from?: string | null;
  to?: string | null;
  sent?: boolean | null;
  paid?: boolean | null;
  periodic?: boolean | null;
}

export type PaymentListView = {
  amount: number;
  paidAt: Date | null;
}

export type HttpSyncStatus = 'pending' | 'complete' | 'aborted';

export type ClubInvoiceListView = {
  id: number;
  invoiceNumber: string;
  period: string;
  total: number;
  discount: number | null;
  isPeriodic: boolean;
  isPaid: boolean;
  createdDate: Date;
  sentAt: Date | null;
  user: UserListView;
  payment: PaymentListView | null;
  attendants: string;
  isLocked: boolean;
  syncStatus: HttpSyncStatus;
  hasPdf: boolean;
}

export type ClubInvoicesListMeta = {
  total: number;
  discountTotal: number;
}

export type ClubInvoiceIndexInput = {
  club: number;
  params: ClubInvoiceIndexParams | null;
}

export type ClubInvoiceAllIdsParams = {
  session?: number | null;
  activity?: number | null;
  search?: string | null;
  from?: string | null;
  to?: string | null;
  sent?: boolean | null;
  paid?: boolean | null;
  periodic?: boolean | null;
}

export type ClubInvoiceIdListView = {
  id: number;
}

export type ClubInvoiceAllIdsInput = {
  club: number;
  params: ClubInvoiceAllIdsParams | null;
}

export type ClubInvoiceExportExcelParams = {
  session?: number | null;
  activity?: number | null;
  search?: string | null;
  from?: string | null;
  to?: string | null;
  sent?: boolean | null;
  paid?: boolean | null;
  periodic?: boolean | null;
}

export type ClubInvoiceExportExcelInput = {
  club: number;
  params: ClubInvoiceExportExcelParams | null;
}

export type ClubInvoicesSummary = {
  unsent: number;
  unsentAmount: number;
  unpaid: number;
  unpaidAmount: number;
}

export type ClubInvoiceSummaryInput = {
  club: number;
}

export type ClubInvoiceSendPendingInput = {
  club: number;
}

export type ClubInvoiceGenerateResult = {
  count: number;
}

export type ClubInvoiceGenerateMonthlyInput = {
  club: number;
}

export type ClubInvoicesAddDiscount = {
  ids: number[];
  name: string;
  amount: number;
}

export type ClubInvoiceAddBulkDiscountInput = {
  club: number;
  form: ClubInvoicesAddDiscount;
}

export type ClubInvoicesSendToAccounting = {
  ids: number[];
}

export type ClubInvoicesSentToAccountingResult = {
  reset: number;
  skipped: number;
}

export type ClubInvoiceSendToAccountingInput = {
  club: number;
  form: ClubInvoicesSendToAccounting;
}

export type AttendantListView = {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
}

export type ClubInvoiceItemView = {
  id: number;
  name: string;
  attendants: AttendantListView[];
  price: number;
  amount: number;
  total: number;
  isProduct: boolean;
  isEditable: boolean;
  attendantNames: string;
}

export type InvoiceStatus = 'paid' | 'payment_pending' | 'waiting';

export type PaymentMethod = 'neopay' | 'cash';

export type ClubInvoiceViewPayment = {
  id: number;
  transactionId: string;
  paidAt: Date | null;
  method: PaymentMethod;
  amount: number;
  comment: string | null;
  canDelete: boolean;
}

export type ClubInvoiceView = {
  id: number;
  invoiceNumber: string;
  paymentDate: Date;
  createdDate: Date;
  sentAt: Date | null;
  isReadOnly: boolean;
  period: string;
  items: ClubInvoiceItemView[];
  totalItems: number;
  total: number;
  notes: string | null;
  status: InvoiceStatus;
  user: UserDetailsView;
  syncStatus: HttpSyncStatus;
  paymentLink: string;
  isLocked: boolean;
  payments: ClubInvoiceViewPayment[];
  hasPaymentAmountMismatch: boolean;
  hasPdf: boolean;
}

export type ClubInvoiceShowInput = {
  club: number;
  invoice: number;
}

export type InvoiceCorrectionForm = {
  notes: string | null;
}

export type ClubInvoiceUpdateInput = {
  club: number;
  invoice: number;
  form: InvoiceCorrectionForm;
}

export type ClubInvoiceDeleteInput = {
  club: number;
  invoice: number;
}

export type ClubInvoiceSendInput = {
  club: number;
  invoice: number;
}

export type ClubInvoiceUpdateAndSendInput = {
  club: number;
  invoice: number;
  form: InvoiceCorrectionForm;
}

export type Method = 'HEAD' | 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'PURGE' | 'OPTIONS' | 'TRACE' | 'CONNECT';

export type HttpMessageView = {
  method: Method;
  uri: string;
  requestHeaders: Record<string, string[]>;
  requestData: any;
  responseStatus: number | null;
  responseHeaders: Record<string, string[]> | null;
  responseData: any;
  errorMessage: string | null;
  sentAt: Date;
}

export type ClubInvoiceSync = {
  lastAttemptAt: Date | null;
  messages: HttpMessageView[];
}

export type ClubInvoiceShowSyncInput = {
  club: number;
  invoice: number;
}

export type ClubInvoiceRetrySyncInput = {
  club: number;
  invoice: number;
}

export type InvoiceDiscountForm = {
  name: string;
  amount: number;
}

export type ClubInvoiceAddDiscountInput = {
  club: number;
  invoice: number;
  form: InvoiceDiscountForm;
}

export type InvoiceItemForm = {
  name: string;
  amount: number;
}

export type ClubInvoiceItemUpdateInput = {
  club: number;
  invoice: number;
  item: number;
  form: InvoiceItemForm;
}

export type ClubInvoiceItemDeleteInput = {
  club: number;
  invoice: number;
  item: number;
}

export type InvoicePayment = {
  amount: number;
  comment: string;
}

export type ClubInvoicePaymentCreateInput = {
  club: number;
  invoice: number;
  form: InvoicePayment;
}

export type ClubInvoiceViewPaymentDetails = {
  id: number;
  paidAt: Date | null;
  method: PaymentMethod;
  amount: number;
  comment: string | null;
}

export type ClubInvoicePaymentShowInput = {
  club: number;
  invoice: number;
  payment: number;
}

export type ClubInvoicePaymentUpdateInput = {
  club: number;
  invoice: number;
  payment: number;
  form: InvoicePayment;
}

export type ClubInvoicePaymentDeleteInput = {
  club: number;
  invoice: number;
  payment: number;
}

export type ClubMemberIndexParams = {
  s?: string | null;
}

export type ClubMemberListView = {
  id: number;
  name: string;
  isCoach: boolean;
  isAdmin: boolean;
  user: UserListView;
}

export type ClubMemberIndexInput = {
  club: number;
  params: ClubMemberIndexParams | null;
}

export type ClubMemberDetails = {
  id: number;
  isCoach: boolean;
  isAdmin: boolean;
  user: UserDetailsView;
}

export type ClubMemberShowInput = {
  club: number;
  member: number;
}

export type RolesForm = {
  isCoach: boolean;
  isAdmin: boolean;
}

export type ClubMemberRoles = {
  isCoach: boolean;
  isAdmin: boolean;
}

export type ClubMemberUpdateRolesInput = {
  club: number;
  member: number;
  form: RolesForm;
}

export type PasswordForm = {
  password: string;
}

export type ClubMemberUpdateUserPasswordInput = {
  club: number;
  member: number;
  form: PasswordForm;
}

export type ClubMemberDeleteInput = {
  club: number;
  member: number;
}

export type InviteMemberForm = {
  email: string;
  roles: RolesForm;
}

export type ClubMemberInviteInput = {
  club: number;
  form: InviteMemberForm;
}

export type ClubOrderAttendantDownloadAgreementInput = {
  club: number;
  order: number;
  attendant: number;
}

export type ClubOrderIndexParams = {
  page?: number | null;
  size?: number | null;
  status?: string | null;
  search?: string | null;
  activity?: number | null;
  session?: number | null;
}

export type OrderStatus = 'draft' | 'payment' | 'finalise' | 'invoice' | 'confirmation' | 'complete' | 'canceled';

export type ClubOrderAttendantSessionListItem = {
  name: string;
}

export type ClubOrderAttendantListItem = {
  fullName: string;
  session: ClubOrderAttendantSessionListItem | null;
}

export type ClubOrderPaymentListItem = {
  id: number;
  paidAt: Date | null;
}

export type ClubOrderInvoiceListItem = {
  invoiceNumber: string;
}

export type ClubOrderListItem = {
  id: number;
  number: string;
  receivedAt: Date | null;
  total: number;
  status: OrderStatus;
  user: UserListView;
  attendants: ClubOrderAttendantListItem[];
  payment: ClubOrderPaymentListItem | null;
  invoice: ClubOrderInvoiceListItem | null;
}

export type ClubOrderIndexInput = {
  club: number;
  params: ClubOrderIndexParams | null;
}

export type ClubOrderCountsByStatusParams = {
  search?: string | null;
  activity?: number | null;
  session?: number | null;
}

export type ClubOrderCountsByStatus = {
  draft: number;
  payment: number;
  finalise: number;
  invoice: number;
  confirmation: number;
  complete: number;
  canceled: number;
}

export type ClubOrderCountsByStatusInput = {
  club: number;
  params: ClubOrderCountsByStatusParams | null;
}

export type ClubOrderInvoice = {
  id: number;
  invoiceNumber: string;
}

export type ClubOrderPayment = {
  amount: number;
  info: string | null;
  id: number;
  paidAt: Date | null;
}

export type ClubOrderAttendantActivity = {
  name: string;
  photo: MediaView | null;
}

export type ClubOrderAttendantSession = {
  name: string;
  activity: ClubOrderAttendantActivity;
}

export type ClubOrderAttendant = {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  dateOfBirth: Date;
  agreement: PdfFileDetails | null;
  session: ClubOrderAttendantSession | null;
}

export type ClubOrderDetails = {
  id: number;
  number: string;
  status: OrderStatus;
  receivedAt: Date | null;
  total: number;
  invoice: ClubOrderInvoice | null;
  payment: ClubOrderPayment | null;
  user: UserDetailsView;
  attendants: ClubOrderAttendant[];
}

export type ClubOrderShowInput = {
  club: number;
  order: number;
}

export type MemberDropdownOption = {
  id: number;
  name: string;
  user: UserListView;
}

export type ClubSettings = {
  notifyOnOrder: MemberDropdownOption[];
  notifyAbsences: MemberDropdownOption[];
}

export type ClubSettingsIndexInput = {
  club: number;
}

export type ClubSettingsForm = {
  notifyOnOrder: Entity[];
  notifyAbsences: Entity[];
}

export type ClubSettingsUpdateInput = {
  club: number;
  form: ClubSettingsForm;
}

export type ClubAbsenceSettings = {
  maxBacktrackDays: number | null;
  uncompensatedDays: number | null;
  compensatedDiscount: number | null;
}

export type ClubSettingsSessionAbsenceSettingsInput = {
  club: number;
}

export type ClubAbsenceSettingsForm = {
  maxBacktrackDays: number | null;
  uncompensatedDays: number | null;
  compensatedDiscount: number | null;
}

export type ClubSettingsUpdateSessionAbsenceSettingsInput = {
  club: number;
  form: ClubAbsenceSettingsForm;
}

export type ClubSurveyIndexParams = {
  attendant?: number | null;
}

export type AttendantSurveyListView = {
  id: number;
  fullName: string;
  guardianFullName: string;
  comment: string | null;
  createdAt: Date;
}

export type ClubSurveyIndexInput = {
  club: number;
  params: ClubSurveyIndexParams | null;
}

export type ClubSurveyDownloadInput = {
  club: number;
  survey: number;
}

export type ParentSessionListActivity = {
  name: string;
  type: ActivityType;
  photo: MediaView | null;
}

export type MySessionListItem = {
  id: number;
  activity: ParentSessionListActivity;
  shift: ParentShiftView;
  weekSchedule: WeekScheduleListView[];
}

export type MySessionRegistrationListItem = {
  id: number;
  attendant: AttendantListView;
  session: MySessionListItem;
}

export type AttendantRegistrationAgreementInput = {
  registration: number;
}

export type MySessionRegistrationClub = {
  name: string;
}

export type MySessionRegistrationActivity = {
  type: ActivityType;
  club: MySessionRegistrationClub;
}

export type MySessionRegistrationCoach = {
  user: UserListView;
}

export type MySessionRegistrationSession = {
  id: number;
  address: string | null;
  price: number;
  shift: ParentShiftView;
  activity: MySessionRegistrationActivity;
  coach: MySessionRegistrationCoach;
  weekDaySchedule: WeekScheduleListView[];
}

export type SessionAbsenceDetails = {
  id: number;
  start: Date;
  end: Date;
  comment: string;
  status: SessionAbsenceStatus;
  adminComment: string | null;
  approvedDiscount: number | null;
}

export type MySessionRegistrationDetails = {
  id: number;
  startDate: Date;
  hasAgreement: boolean;
  session: MySessionRegistrationSession;
  attendant: AttendantListView;
  results: SessionResultView[];
  absences: SessionAbsenceDetails[];
  absenceMinDate: Date | null;
  absenceMaxDate: Date;
}

export type AttendantRegistrationShowInput = {
  registration: number;
}

export type SubmitAbsenceForm = {
  start: Date;
  end: Date;
  comment: string;
}

export type AttendantRegistrationSubmitAbsenceInput = {
  registration: number;
  form: SubmitAbsenceForm;
}

export type AttendantRegistrationUpdateAbsenceInput = {
  registration: number;
  absence: number;
  form: SubmitAbsenceForm;
}

export type AdminPaymentIndexParams = {
  page?: number | null;
  size?: number | null;
}

export type PaymentStatus = 'pending' | 'paid' | 'canceled';

export type AdminPaymentListActivity = {
  name: string;
}

export type AdminPaymentListSession = {
  name: string;
}

export type AdminPaymentListAttendant = {
  fullName: string;
  session: AdminPaymentListSession | null;
}

export type AdminPaymentListOrder = {
  receivedAt: Date;
  user: UserListView;
  activity: AdminPaymentListActivity | null;
  attendants: AdminPaymentListAttendant[];
}

export type AdminPaymentListView = {
  id: number;
  transactionId: string;
  amount: number;
  purpose: string;
  status: PaymentStatus;
  paidAt: Date | null;
  order: AdminPaymentListOrder | null;
}

export type AdminPaymentIndexInput = {
  params: AdminPaymentIndexParams | null;
}

export type AdminPaymentMarkPaidInput = {
  payment: number;
}

export type AttendantDetailsView = {
  dateOfBirth: Date;
  user: UserDetailsView;
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
}

export type AttendantShowInput = {
  attendant: number;
}

export type AttendantForm = {
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
}

export type AttendantUpdateInput = {
  attendant: number;
  form: AttendantForm;
}

export type AttendantCreateInput = {
  form: AttendantForm;
}

export type AttendantDeleteInput = {
  attendant: number;
}

export type CreateTokenForm = {
  email: string;
  password: string;
  mobile?: boolean | null;
}

export type AccessToken = {
  accessToken: string;
}

export type AuthCreateTokenInput = {
  form: CreateTokenForm;
}

export type UpdateMyDetails = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export type AuthUpdateMeInput = {
  form: UpdateMyDetails;
}

export type PasswordRecoveryForm = {
  email: string;
}

export type AuthStartRecoveryInput = {
  form: PasswordRecoveryForm;
}

export type RecoveryStatus = {
  valid: boolean;
}

export type AuthCheckRecoveryInput = {
  token: string;
}

export type NewPassword = {
  token: string;
  password: string;
}

export type AuthChangePasswordInput = {
  form: NewPassword;
}

export type CategoryDetailsView = {
  id: number;
  name: string;
  photo: MediaView | null;
}

export type CategoryShowInput = {
  category: number;
}

export type AddCategoryForm = {
  name: string;
}

export type AddCategoryFormFiles = {
  photo: File
}

export type CategoryCreateInput = {
  form: AddCategoryForm;
  files: AddCategoryFormFiles | null;
}

export type UpdateCategoryForm = {
  name: string;
}

export type UpdateCategoryFormFiles = {
  photo: File
}

export type CategoryUpdateInput = {
  category: number;
  form: UpdateCategoryForm;
  files: UpdateCategoryFormFiles | null;
}

export type CategoryDeleteInput = {
  category: number;
}

export type AttendantInvitationListView = {
  id: number;
  created: Date;
  link: string;
  session: InvitationSessionView;
}

export type AcceptInvitationAttendant = {
  id: number;
}

export type AcceptInvitationForm = {
  attendants: AcceptInvitationAttendant[];
}

export type AcceptedInvitationsResponse = {
  removed: number;
}

export type InvitationAcceptInput = {
  invitation: number;
  form: AcceptInvitationForm;
}

export type InvitationDeleteInput = {
  invitation: number;
}

export type ParentInvoiceItemAttendantListView = {
  firstName: string;
  lastName: string;
}

export type ParentInvoiceItemListView = {
  name: string;
  attendants: ParentInvoiceItemAttendantListView[];
  amount: number;
  price: number;
  total: number;
}

export type ParentInvoiceListView = {
  id: number;
  invoiceNumber: string;
  period: string;
  total: number;
  totalItems: number;
  status: InvoiceStatus;
  paymentDate: Date;
  token: string;
  hasPdf: boolean;
  items: ParentInvoiceItemListView[];
}

export type InvoiceShowInput = {
  token: string;
}

export type InitiatePaymentResponse = {
  redirectUrl: string;
}

export type InvoiceStartTokenPaymentInput = {
  token: string;
}

export type InvoiceStartPaymentInput = {
  invoice: number;
}

export type InvoiceDownloadPdfInput = {
  invoice: number;
}

export type MemberInvitationUser = {
  firstName: string | null;
  lastName: string | null;
  fullName: string | null;
  hasPassword: boolean;
}

export type MemberInvitationDetails = {
  email: string;
  token: string;
  club: ClubListView;
  user: MemberInvitationUser | null;
  existingRoles: ClubMemberRoles;
  roles: ClubMemberRoles;
}

export type MemberInvitationShowInput = {
  token: string;
}

export type AcceptMemberInvitationUser = {
  firstName: string;
  lastName: string;
  password: string;
}

export type AcceptMemberInvitation = {
  user: AcceptMemberInvitationUser | null;
}

export type AcceptMemberInvitationResultUser = {
  id: number;
}

export type AcceptMemberInvitationResult = {
  id: number;
  user: AcceptMemberInvitationResultUser;
}

export type MemberInvitationAcceptInput = {
  form: AcceptMemberInvitation;
  token: string;
}

export type HomePageSettings = {
  link: string | null;
}

export type HomePageSettingsForm = {
  link: string;
}

export type MenuUpdateHomePageSettingsInput = {
  form: HomePageSettingsForm;
}

export type MenuKey = 'header' | 'footer';

export type MenuItemListView = {
  id: number;
  name: string;
}

export type MenuDetails = {
  menuKey: MenuKey;
  items: MenuItemListView[];
}

export type MenuShowInput = {
  menuKey: MenuKey;
}

export type EntityPosition = {
  id: number;
  sort: number;
}

export type EntitySortForm = {
  items: EntityPosition[];
}

export type MenuUpdateSortingInput = {
  menuKey: MenuKey;
  form: EntitySortForm;
}

export type MenuItemType = 'page' | 'external';

export type PageDetails = {
  content: EditorJSData | null;
  id: number;
  name: string;
  title: string;
  slug: string;
  isReadOnly: boolean;
}

export type MenuItemDetails = {
  type: MenuItemType;
  page: PageDetails | null;
  url: string | null;
  id: number;
  name: string;
}

export type MenuShowItemInput = {
  menuKey: MenuKey;
  item: number;
}

export type MenuItemUpdate = {
  name: string;
  type: MenuItemType;
  url: string | null;
  page: Entity | null;
}

export type MenuUpdateItemInput = {
  menuKey: MenuKey;
  item: number;
  form: MenuItemUpdate;
}

export type AddMenuItem = {
  name: string;
}

export type MenuCreateItemInput = {
  menuKey: MenuKey;
  form: AddMenuItem;
}

export type MenuDeleteItemInput = {
  menuKey: MenuKey;
  item: number;
}

export type MessageListView = {
  id: number;
  title: string;
  content: string;
  sent: Date;
  author: UserListView;
}

export type MessageDetailsView = {
  images: MediaView[];
  id: number;
  title: string;
  content: string;
  sent: Date;
  author: UserListView;
}

export type MessageShowInput = {
  message: number;
}

export type PageListView = {
  id: number;
  name: string;
  title: string;
  slug: string;
  isReadOnly: boolean;
}

export type PageShowInput = {
  page: number;
}

export type PageForm = {
  title: string;
  content: EditorJSData;
  slug: string;
}

export type PageCreateInput = {
  form: PageForm;
}

export type PageUpdateInput = {
  page: number;
  form: PageForm;
}

export type PageDeleteInput = {
  page: number;
}

export type PaymentPayload = {
  token: string;
}

export type PaymentConfirmInput = {
  form: PaymentPayload;
}

export type PaymentCancelInput = {
  form: PaymentPayload;
}

export type PublicHolidayView = {
  id: number;
  date: Date;
}

export type CreatePublicHoliday = {
  date: Date;
}

export type PublicHolidayCreateInput = {
  form: CreatePublicHoliday;
}

export type PublicHolidayDeleteInput = {
  holiday: number;
}

export type DayScheduleActivity = {
  name: string;
}

export type ParentSessionListRegistration = {
  attendant: AttendantListView;
}

export type DayScheduleSessionView = {
  id: number;
  activity: DayScheduleActivity;
  attendantRegistrations: ParentSessionListRegistration[];
}

export type DayScheduleSlot = {
  startTime: string;
  sessions: DayScheduleSessionView[];
}

export type DaySchedule = {
  publicHoliday: boolean;
  slots: DayScheduleSlot[];
}

export type ScheduleDayScheduleInput = {
  date: Date;
}

export type PeriodSummaryView = {
  date: Date;
  hasSessions: boolean;
}

export type SchedulePeriodSummaryInput = {
  start: Date;
  end: Date;
}

export type SessionIndexParams = {
  city?: number | null;
  category?: number | null;
}

export type ActivityListView = {
  id: number;
  name: string;
  description: string;
  photo: MediaView | null;
}

export type SessionListView = {
  id: number;
  name: string;
  activity: ActivityListView;
  coach: CoachListView;
  hasVacancies: boolean;
}

export type SessionIndexInput = {
  params: SessionIndexParams | null;
}

export type ParentSessionDetailsActivity = {
  name: string;
  type: ActivityType;
  description: string;
  photo: MediaView | null;
}

export type ParentSessionDetailsClub = {
  name: string;
}

export type ParentSessionDetailsCoach = {
  user: UserDetailsView;
  club: ParentSessionDetailsClub;
}

export type ParentSessionDetailsView = {
  id: number;
  name: string;
  shift: ParentShiftView;
  price: number;
  address: string | null;
  published: boolean;
  ageGroupFrom: number | null;
  ageGroupTo: number | null;
  hasVacancies: boolean;
  activity: ParentSessionDetailsActivity;
  coach: ParentSessionDetailsCoach;
  weekSchedule: WeekScheduleListView[];
}

export type SessionShowInput = {
  session: number;
}

export type Beat = {
  value: number;
  isPeak: boolean;
}

export type AttendantSurveySubmit = {
  intervalMillis: number;
  comment: string | null;
  beats: Beat[];
  rr: unknown[];
}

export type AttendantSurveyDetails = {
  id: number;
  createdAt: Date;
  intervalMillis: number;
  comment: string | null;
  beats: unknown[];
  rr: unknown[];
}

export type SurveyCreateInput = {
  form: AttendantSurveySubmit;
}

export async function activityIndex({ club }: ActivityIndexInput) {
  const data = (
    await http.get<ApiResponse<CoachActivityListView[], null>>(
      `/api/v1/clubs/${club}/activities`
    )
  ).data.data;
  
  return data.map(item => prepareCoachActivityListView(item))
}

export async function activityDropdown({ club }: ActivityDropdownInput) {
  const data = (
    await http.get<ApiResponse<ActivityDropdownOption[], null>>(
      `/api/v1/clubs/${club}/activities/dropdown`
    )
  ).data.data;
  
  return data;
}

export async function activityCreate({ club, form, files }: ActivityCreateInput) {
  const data = (
    await http.post<ApiResponse<CoachActivityListView, null>>(
      `/api/v1/clubs/${club}/activities`, makeFormData(form, files), { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  ).data.data;
  
  return prepareCoachActivityListView(data)
}

export async function activityDelete({ club, activity }: ActivityDeleteInput) {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/clubs/${club}/activities/${activity}`
    )
  ).data.data;
  
  return data;
}

export async function activityShow({ club, activity }: ActivityShowInput) {
  const data = (
    await http.get<ApiResponse<CoachActivityDetailsView, null>>(
      `/api/v1/clubs/${club}/activities/${activity}`
    )
  ).data.data;
  
  return data;
}

export async function activityUpdate({ club, activity, form, files }: ActivityUpdateInput) {
  const data = (
    await http.put<ApiResponse<CoachActivityDetailsView, null>>(
      `/api/v1/clubs/${club}/activities/${activity}`, makeFormData(form, files), { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  ).data.data;
  
  return data;
}

export async function activityShowAgreement({ club, activity }: ActivityShowAgreementInput) {
  const data = (
    await http.get<ApiResponse<ActivityEditorTemplateSettings, null>>(
      `/api/v1/clubs/${club}/activities/${activity}/agreement`
    )
  ).data.data;
  
  return data;
}

export async function activityUpdateAgreement({ club, activity, form }: ActivityUpdateAgreementInput) {
  const data = (
    await http.put<ApiResponse<ActivityEditorTemplateSettings, null>>(
      `/api/v1/clubs/${club}/activities/${activity}/agreement`, form
    )
  ).data.data;
  
  return data;
}

export async function activityShowPersonalDataAgreement({ club, activity }: ActivityShowPersonalDataAgreementInput) {
  const data = (
    await http.get<ApiResponse<EditorTemplateSettings, null>>(
      `/api/v1/clubs/${club}/activities/${activity}/personal-data-agreement`
    )
  ).data.data;
  
  return data;
}

export async function activityUpdatePersonalDataAgreement({ club, activity, form }: ActivityUpdatePersonalDataAgreementInput) {
  const data = (
    await http.post<ApiResponse<EditorTemplateSettings, null>>(
      `/api/v1/clubs/${club}/activities/${activity}/personal-data-agreement`, form
    )
  ).data.data;
  
  return data;
}

export async function activityShowConfirmationEmail({ club, activity }: ActivityShowConfirmationEmailInput) {
  const data = (
    await http.get<ApiResponse<ActivityConfirmationEmailSettings, null>>(
      `/api/v1/clubs/${club}/activities/${activity}/confirmation-email`
    )
  ).data.data;
  
  return data;
}

export async function activityUpdateConfirmationEmail({ club, activity, form }: ActivityUpdateConfirmationEmailInput) {
  const data = (
    await http.post<ApiResponse<ActivityConfirmationEmailSettings, null>>(
      `/api/v1/clubs/${club}/activities/${activity}/confirmation-email`, form
    )
  ).data.data;
  
  return data;
}

export async function activityAttendantExport({ club, activity }: ActivityAttendantExportInput) {
  const data = (
    await http.get<ApiResponse<null, null>>(
      `/api/v1/clubs/${club}/activities/${activity}/attendant-export`
    )
  ).data.data;
  
  return data;
}

export async function activityQuantityDiscountIndex({ activity }: ActivityQuantityDiscountIndexInput) {
  const data = (
    await http.get<ApiResponse<QuantityDiscountView[], null>>(
      `/api/v1/activities/${activity}/quantity-discounts`
    )
  ).data.data;
  
  return data;
}

export async function activityQuantityDiscountCreate({ activity, form }: ActivityQuantityDiscountCreateInput) {
  const data = (
    await http.post<ApiResponse<QuantityDiscountView, null>>(
      `/api/v1/activities/${activity}/quantity-discounts`, form
    )
  ).data.data;
  
  return data;
}

export async function activityQuantityDiscountShow({ activity, discount }: ActivityQuantityDiscountShowInput) {
  const data = (
    await http.get<ApiResponse<QuantityDiscountView, null>>(
      `/api/v1/activities/${activity}/quantity-discounts/${discount}`
    )
  ).data.data;
  
  return data;
}

export async function activityQuantityDiscountUpdate({ activity, discount, form }: ActivityQuantityDiscountUpdateInput) {
  const data = (
    await http.put<ApiResponse<QuantityDiscountView, null>>(
      `/api/v1/activities/${activity}/quantity-discounts/${discount}`, form
    )
  ).data.data;
  
  return data;
}

export async function activityQuantityDiscountDelete({ activity, discount }: ActivityQuantityDiscountDeleteInput) {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/activities/${activity}/quantity-discounts/${discount}`
    )
  ).data.data;
  
  return data;
}

export async function activitySessionAttendantExport({ activity, session }: ActivitySessionAttendantExportInput) {
  const data = (
    await http.get<ApiResponse<null, null>>(
      `/api/v1/activities/${activity}/sessions/${session}/attendants/export`
    )
  ).data.data;
  
  return data;
}

export async function activitySessionIndex({ activity }: ActivitySessionIndexInput) {
  const data = (
    await http.get<ApiResponse<CoachShiftListView[], null>>(
      `/api/v1/activities/${activity}/sessions`
    )
  ).data.data;
  
  return data.map(item => prepareCoachShiftListView(item))
}

export async function activitySessionShow({ activity, session }: ActivitySessionShowInput) {
  const data = (
    await http.get<ApiResponse<CoachSessionDetailsView, null>>(
      `/api/v1/activities/${activity}/sessions/${session}`
    )
  ).data.data;
  
  return prepareCoachSessionDetailsView(data)
}

export async function activitySessionCreate({ activity, form }: ActivitySessionCreateInput) {
  const data = (
    await http.post<ApiResponse<CoachSessionListView, null>>(
      `/api/v1/activities/${activity}/sessions`, prepareAddSessionForm(form)
    )
  ).data.data;
  
  return prepareCoachSessionListView(data)
}

export async function activitySessionUpdate({ activity, session, form }: ActivitySessionUpdateInput) {
  const data = (
    await http.put<ApiResponse<CoachSessionDetailsView, null>>(
      `/api/v1/activities/${activity}/sessions/${session}`, prepareUpdateSessionForm(form)
    )
  ).data.data;
  
  return prepareCoachSessionDetailsView(data)
}

export async function activitySessionDelete({ activity, session }: ActivitySessionDeleteInput) {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/activities/${activity}/sessions/${session}`
    )
  ).data.data;
  
  return data;
}

export async function activitySessionSendMessage({ activity, session, form, files }: ActivitySessionSendMessageInput) {
  const data = (
    await http.post<ApiResponse<SessionMessageSent, null>>(
      `/api/v1/activities/${activity}/sessions/${session}/messages`, makeFormData(form, files), { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  ).data.data;
  
  return data;
}

export async function activitySessionInvitationIndex({ activity, session }: ActivitySessionInvitationIndexInput) {
  const data = (
    await http.get<ApiResponse<CoachSessionInvitationListView[], null>>(
      `/api/v1/activities/${activity}/sessions/${session}/invitations`
    )
  ).data.data;
  
  return data.map(item => prepareCoachSessionInvitationListView(item))
}

export async function activitySessionInvitationSend({ activity, session, form }: ActivitySessionInvitationSendInput) {
  const data = (
    await http.post<ApiResponse<SessionInvitationsSent, null>>(
      `/api/v1/activities/${activity}/sessions/${session}/invitations`, form
    )
  ).data.data;
  
  return data;
}

export async function activitySessionInvitationResend({ activity, session, invitation }: ActivitySessionInvitationResendInput) {
  const data = (
    await http.post<ApiResponse<AttendantInvitationDetailsView, null>>(
      `/api/v1/activities/${activity}/sessions/${session}/invitations/${invitation}/resends`, null
    )
  ).data.data;
  
  return prepareAttendantInvitationDetailsView(data)
}

export async function activitySessionInvitationDelete({ activity, session, invitation }: ActivitySessionInvitationDeleteInput) {
  const data = (
    await http.get<ApiResponse<null, null>>(
      `/api/v1/activities/${activity}/sessions/${session}/invitations/${invitation}`
    )
  ).data.data;
  
  return data;
}

export async function activitySessionRegistrationIndex({ activity, session }: ActivitySessionRegistrationIndexInput) {
  const data = (
    await http.get<ApiResponse<CoachAttendantRegistrationListView[], null>>(
      `/api/v1/activities/${activity}/sessions/${session}/registrations`
    )
  ).data.data;
  
  return data.map(item => prepareCoachAttendantRegistrationListView(item))
}

export async function activitySessionRegistrationShow({ activity, session, registration }: ActivitySessionRegistrationShowInput) {
  const data = (
    await http.get<ApiResponse<CoachAttendantRegistrationDetails, null>>(
      `/api/v1/activities/${activity}/sessions/${session}/registrations/${registration}`
    )
  ).data.data;
  
  return prepareCoachAttendantRegistrationDetails(data)
}

export async function activitySessionRegistrationUpdate({ activity, session, registration, form }: ActivitySessionRegistrationUpdateInput) {
  const data = (
    await http.put<ApiResponse<CoachAttendantRegistrationDetails, null>>(
      `/api/v1/activities/${activity}/sessions/${session}/registrations/${registration}`, form
    )
  ).data.data;
  
  return prepareCoachAttendantRegistrationDetails(data)
}

export async function activitySessionRegistrationDelete({ activity, session, registration }: ActivitySessionRegistrationDeleteInput) {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/activities/${activity}/sessions/${session}/registrations/${registration}`
    )
  ).data.data;
  
  return data;
}

export async function activitySessionRegistrationChangeSession({ activity, session, registration, form }: ActivitySessionRegistrationChangeSessionInput) {
  const data = (
    await http.put<ApiResponse<null, null>>(
      `/api/v1/activities/${activity}/sessions/${session}/registrations/${registration}/session`, form
    )
  ).data.data;
  
  return data;
}

export async function activitySessionRegistrationAddResult({ activity, session, registration, form }: ActivitySessionRegistrationAddResultInput) {
  const data = (
    await http.post<ApiResponse<SessionResultView, null>>(
      `/api/v1/activities/${activity}/sessions/${session}/registrations/${registration}/results`, form
    )
  ).data.data;
  
  return prepareSessionResultView(data)
}

export async function activitySessionReservationIndex({ activity, session }: ActivitySessionReservationIndexInput) {
  const data = (
    await http.get<ApiResponse<CoachSessionReservationListView[], null>>(
      `/api/v1/activities/${activity}/sessions/${session}/reservations`
    )
  ).data.data;
  
  return data.map(item => prepareCoachSessionReservationListView(item))
}

export async function activitySessionReservationUpdate({ activity, session, reservation, form }: ActivitySessionReservationUpdateInput) {
  const data = (
    await http.put<ApiResponse<CoachSessionReservationListView, null>>(
      `/api/v1/activities/${activity}/sessions/${session}/reservations/${reservation}`, form
    )
  ).data.data;
  
  return prepareCoachSessionReservationListView(data)
}

export async function activitySessionReservationDelete({ activity, session, reservation }: ActivitySessionReservationDeleteInput) {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/activities/${activity}/sessions/${session}/reservations/${reservation}`
    )
  ).data.data;
  
  return data;
}

export async function activitySessionReservationInvite({ activity, session, reservation }: ActivitySessionReservationInviteInput) {
  const data = (
    await http.post<ApiResponse<CoachSessionInvitationListView, null>>(
      `/api/v1/activities/${activity}/sessions/${session}/reservations/${reservation}/invitation`, null
    )
  ).data.data;
  
  return prepareCoachSessionInvitationListView(data)
}

export async function activityShiftIndex({ activity }: ActivityShiftIndexInput) {
  const data = (
    await http.get<ApiResponse<ShiftView[], null>>(
      `/api/v1/activities/${activity}/shifts`
    )
  ).data.data;
  
  return data.map(item => prepareShiftView(item))
}

export async function activityShiftCreate({ activity, form }: ActivityShiftCreateInput) {
  const data = (
    await http.post<ApiResponse<ShiftView, null>>(
      `/api/v1/activities/${activity}/shifts`, prepareShiftForm(form)
    )
  ).data.data;
  
  return prepareShiftView(data)
}

export async function activityShiftUpdate({ activity, shift, form }: ActivityShiftUpdateInput) {
  const data = (
    await http.put<ApiResponse<ShiftView, null>>(
      `/api/v1/activities/${activity}/shifts/${shift}`, prepareShiftForm(form)
    )
  ).data.data;
  
  return prepareShiftView(data)
}

export async function activityShiftDelete({ activity, shift }: ActivityShiftDeleteInput) {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/activities/${activity}/shifts/${shift}`
    )
  ).data.data;
  
  return data;
}

export async function clubAbsenceIndex({ club, params }: ClubAbsenceIndexInput) {
  const data = (
    await http.get<ApiResponse<AdminSessionAbsenceListItem[], null>>(
      `/api/v1/clubs/${club}/absences`, { params }
    )
  ).data;
  
  return {...data, data: data.data.map(item => prepareAdminSessionAbsenceListItem(item))}
}

export async function clubAbsenceCountsByStatus({ club }: ClubAbsenceCountsByStatusInput) {
  const data = (
    await http.get<ApiResponse<AdminSessionAbsenceCountsByStatus, null>>(
      `/api/v1/clubs/${club}/absences/counts-by-status`
    )
  ).data.data;
  
  return data;
}

export async function clubAbsenceApprove({ club, form }: ClubAbsenceApproveInput) {
  const data = (
    await http.post<ApiResponse<AdminApprovedSessions, null>>(
      `/api/v1/clubs/${club}/absences/approved`, form
    )
  ).data.data;
  
  return data;
}

export async function clubAbsenceShow({ club, absence }: ClubAbsenceShowInput) {
  const data = (
    await http.get<ApiResponse<AdminSessionAbsenceDetails, null>>(
      `/api/v1/clubs/${club}/absences/${absence}`
    )
  ).data.data;
  
  return data;
}

export async function clubAbsenceUpdate({ club, absence, form }: ClubAbsenceUpdateInput) {
  const data = (
    await http.put<ApiResponse<AdminSessionAbsenceDetails, null>>(
      `/api/v1/clubs/${club}/absences/${absence}`, form
    )
  ).data.data;
  
  return data;
}

export async function clubAttendantIndex({ club, params }: ClubAttendantIndexInput) {
  const data = (
    await http.get<ApiResponse<ClubAttendantListView[], null>>(
      `/api/v1/clubs/${club}/attendants`, { params }
    )
  ).data;
  
  return {...data, data: data.data.map(item => prepareClubAttendantListView(item))}
}

export async function clubAttendantDropdown({ club }: ClubAttendantDropdownInput) {
  const data = (
    await http.get<ApiResponse<AttendantDropdownOption[], null>>(
      `/api/v1/clubs/${club}/attendants/dropdown`
    )
  ).data.data;
  
  return data;
}

export async function clubAttendantExport({ club }: ClubAttendantExportInput) {
  const data = (
    await http.get<ApiResponse<null, null>>(
      `/api/v1/clubs/${club}/attendants/export`
    )
  ).data.data;
  
  return data;
}

export async function clubAttendantShow({ club, attendant }: ClubAttendantShowInput) {
  const data = (
    await http.get<ApiResponse<ClubAttendantDetails, null>>(
      `/api/v1/clubs/${club}/attendants/${attendant}`
    )
  ).data.data;
  
  return prepareClubAttendantDetails(data)
}

export async function clubAttendantUpdate({ club, attendant, form }: ClubAttendantUpdateInput) {
  const data = (
    await http.put<ApiResponse<ClubAttendantDetails, null>>(
      `/api/v1/clubs/${club}/attendants/${attendant}`, prepareAdminAttendantForm(form)
    )
  ).data.data;
  
  return prepareClubAttendantDetails(data)
}

export async function clubAttendantSessions({ club, attendant }: ClubAttendantSessionsInput) {
  const data = (
    await http.get<ApiResponse<ClubAttendantRegistrationListView[], null>>(
      `/api/v1/clubs/${club}/attendants/${attendant}/registrations`
    )
  ).data.data;
  
  return data.map(item => prepareClubAttendantRegistrationListView(item))
}

export async function clubMyClubs() {
  const data = (
    await http.get<ApiResponse<MyClubView[], null>>(
      `/api/v1/clubs/my-clubs`
    )
  ).data.data;
  
  return data;
}

export async function clubShow({ club }: ClubShowInput) {
  const data = (
    await http.get<ApiResponse<ClubDetailsView, null>>(
      `/api/v1/clubs/${club}`
    )
  ).data.data;
  
  return data;
}

export async function clubUpdate({ club, form, files }: ClubUpdateInput) {
  const data = (
    await http.post<ApiResponse<ClubDetailsView, null>>(
      `/api/v1/clubs/${club}`, makeFormData(form, files), { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  ).data.data;
  
  return data;
}

export async function clubSessions({ club, params }: ClubSessionsInput) {
  const data = (
    await http.get<ApiResponse<SessionDropdownOption[], null>>(
      `/api/v1/clubs/${club}/sessions`, { params }
    )
  ).data.data;
  
  return data;
}

export async function clubCoaches({ club }: ClubCoachesInput) {
  const data = (
    await http.get<ApiResponse<CoachBaseView[], null>>(
      `/api/v1/clubs/${club}/coaches`
    )
  ).data.data;
  
  return data;
}

export async function clubDiscountIndex({ club, params }: ClubDiscountIndexInput) {
  const data = (
    await http.get<ApiResponse<DiscountListView[], null>>(
      `/api/v1/clubs/${club}/discounts`, { params }
    )
  ).data.data;
  
  return data.map(item => prepareDiscountListView(item))
}

export async function clubDiscountShow({ club, discount }: ClubDiscountShowInput) {
  const data = (
    await http.get<ApiResponse<DiscountDetails, null>>(
      `/api/v1/clubs/${club}/discounts/${discount}`
    )
  ).data.data;
  
  return prepareDiscountDetails(data)
}

export async function clubDiscountCreate({ club, form }: ClubDiscountCreateInput) {
  const data = (
    await http.post<ApiResponse<DiscountListView, null>>(
      `/api/v1/clubs/${club}/discounts`, prepareDiscountForm(form)
    )
  ).data.data;
  
  return prepareDiscountListView(data)
}

export async function clubDiscountUpdate({ club, discount, form }: ClubDiscountUpdateInput) {
  const data = (
    await http.put<ApiResponse<DiscountDetails, null>>(
      `/api/v1/clubs/${club}/discounts/${discount}`, prepareDiscountForm(form)
    )
  ).data.data;
  
  return prepareDiscountDetails(data)
}

export async function clubDiscountDelete({ club, discount }: ClubDiscountDeleteInput) {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/clubs/${club}/discounts/${discount}`
    )
  ).data.data;
  
  return data;
}

export async function clubInvoiceIndex({ club, params }: ClubInvoiceIndexInput) {
  const data = (
    await http.get<ApiResponse<ClubInvoiceListView[], ClubInvoicesListMeta>>(
      `/api/v1/clubs/${club}/invoices`, { params }
    )
  ).data;
  
  return {...data, data: data.data.map(item => prepareClubInvoiceListView(item))}
}

export async function clubInvoiceAllIds({ club, params }: ClubInvoiceAllIdsInput) {
  const data = (
    await http.get<ApiResponse<ClubInvoiceIdListView[], null>>(
      `/api/v1/clubs/${club}/invoices/ids`, { params }
    )
  ).data.data;
  
  return data;
}

export async function clubInvoiceExportExcel({ club, params }: ClubInvoiceExportExcelInput) {
  const data = (
    await http.get<ApiResponse<null, null>>(
      `/api/v1/clubs/${club}/invoices/xlsx`, { params }
    )
  ).data.data;
  
  return data;
}

export async function clubInvoiceSummary({ club }: ClubInvoiceSummaryInput) {
  const data = (
    await http.get<ApiResponse<ClubInvoicesSummary, null>>(
      `/api/v1/clubs/${club}/invoices/summary`
    )
  ).data.data;
  
  return data;
}

export async function clubInvoiceSendPending({ club }: ClubInvoiceSendPendingInput) {
  const data = (
    await http.post<ApiResponse<ClubInvoiceListView[], null>>(
      `/api/v1/clubs/${club}/invoices/send`, null
    )
  ).data.data;
  
  return data.map(item => prepareClubInvoiceListView(item))
}

export async function clubInvoiceGenerateMonthly({ club }: ClubInvoiceGenerateMonthlyInput) {
  const data = (
    await http.put<ApiResponse<ClubInvoiceGenerateResult, null>>(
      `/api/v1/clubs/${club}/invoices/monthly`, null
    )
  ).data.data;
  
  return data;
}

export async function clubInvoiceAddBulkDiscount({ club, form }: ClubInvoiceAddBulkDiscountInput) {
  const data = (
    await http.post<ApiResponse<null, null>>(
      `/api/v1/clubs/${club}/invoices/discount`, form
    )
  ).data.data;
  
  return data;
}

export async function clubInvoiceSendToAccounting({ club, form }: ClubInvoiceSendToAccountingInput) {
  const data = (
    await http.post<ApiResponse<ClubInvoicesSentToAccountingResult, null>>(
      `/api/v1/clubs/${club}/invoices/accounting`, form
    )
  ).data.data;
  
  return data;
}

export async function clubInvoiceShow({ club, invoice }: ClubInvoiceShowInput) {
  const data = (
    await http.get<ApiResponse<ClubInvoiceView, null>>(
      `/api/v1/clubs/${club}/invoices/${invoice}`
    )
  ).data.data;
  
  return prepareClubInvoiceView(data)
}

export async function clubInvoiceUpdate({ club, invoice, form }: ClubInvoiceUpdateInput) {
  const data = (
    await http.post<ApiResponse<ClubInvoiceView, null>>(
      `/api/v1/clubs/${club}/invoices/${invoice}`, form
    )
  ).data.data;
  
  return prepareClubInvoiceView(data)
}

export async function clubInvoiceDelete({ club, invoice }: ClubInvoiceDeleteInput) {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/clubs/${club}/invoices/${invoice}`
    )
  ).data.data;
  
  return data;
}

export async function clubInvoiceSend({ club, invoice }: ClubInvoiceSendInput) {
  const data = (
    await http.post<ApiResponse<ClubInvoiceView, null>>(
      `/api/v1/clubs/${club}/invoices/${invoice}/send`, null
    )
  ).data.data;
  
  return prepareClubInvoiceView(data)
}

export async function clubInvoiceUpdateAndSend({ club, invoice, form }: ClubInvoiceUpdateAndSendInput) {
  const data = (
    await http.post<ApiResponse<ClubInvoiceView, null>>(
      `/api/v1/clubs/${club}/invoices/${invoice}/update-send`, form
    )
  ).data.data;
  
  return prepareClubInvoiceView(data)
}

export async function clubInvoiceShowSync({ club, invoice }: ClubInvoiceShowSyncInput) {
  const data = (
    await http.get<ApiResponse<ClubInvoiceSync, null>>(
      `/api/v1/clubs/${club}/invoices/${invoice}/sync`
    )
  ).data.data;
  
  return prepareClubInvoiceSync(data)
}

export async function clubInvoiceRetrySync({ club, invoice }: ClubInvoiceRetrySyncInput) {
  const data = (
    await http.post<ApiResponse<ClubInvoiceSync, null>>(
      `/api/v1/clubs/${club}/invoices/${invoice}/sync`, null
    )
  ).data.data;
  
  return prepareClubInvoiceSync(data)
}

export async function clubInvoiceAddDiscount({ club, invoice, form }: ClubInvoiceAddDiscountInput) {
  const data = (
    await http.post<ApiResponse<ClubInvoiceView, null>>(
      `/api/v1/clubs/${club}/invoices/${invoice}/discounts`, form
    )
  ).data.data;
  
  return prepareClubInvoiceView(data)
}

export async function clubInvoiceItemUpdate({ club, invoice, item, form }: ClubInvoiceItemUpdateInput) {
  const data = (
    await http.put<ApiResponse<ClubInvoiceItemView, null>>(
      `/api/v1/clubs/${club}/invoices/${invoice}/items/${item}`, form
    )
  ).data.data;
  
  return data;
}

export async function clubInvoiceItemDelete({ club, invoice, item }: ClubInvoiceItemDeleteInput) {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/clubs/${club}/invoices/${invoice}/items/${item}`
    )
  ).data.data;
  
  return data;
}

export async function clubInvoicePaymentCreate({ club, invoice, form }: ClubInvoicePaymentCreateInput) {
  const data = (
    await http.post<ApiResponse<ClubInvoiceViewPayment, null>>(
      `/api/v1/clubs/${club}/invoices/${invoice}/payments`, form
    )
  ).data.data;
  
  return prepareClubInvoiceViewPayment(data)
}

export async function clubInvoicePaymentShow({ club, invoice, payment }: ClubInvoicePaymentShowInput) {
  const data = (
    await http.get<ApiResponse<ClubInvoiceViewPaymentDetails, null>>(
      `/api/v1/clubs/${club}/invoices/${invoice}/payments/${payment}`
    )
  ).data.data;
  
  return prepareClubInvoiceViewPaymentDetails(data)
}

export async function clubInvoicePaymentUpdate({ club, invoice, payment, form }: ClubInvoicePaymentUpdateInput) {
  const data = (
    await http.put<ApiResponse<ClubInvoiceViewPaymentDetails, null>>(
      `/api/v1/clubs/${club}/invoices/${invoice}/payments/${payment}`, form
    )
  ).data.data;
  
  return prepareClubInvoiceViewPaymentDetails(data)
}

export async function clubInvoicePaymentDelete({ club, invoice, payment }: ClubInvoicePaymentDeleteInput) {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/clubs/${club}/invoices/${invoice}/payments/${payment}`
    )
  ).data.data;
  
  return data;
}

export async function clubMemberIndex({ club, params }: ClubMemberIndexInput) {
  const data = (
    await http.get<ApiResponse<ClubMemberListView[], null>>(
      `/api/v1/clubs/${club}/members`, { params }
    )
  ).data.data;
  
  return data;
}

export async function clubMemberShow({ club, member }: ClubMemberShowInput) {
  const data = (
    await http.get<ApiResponse<ClubMemberDetails, null>>(
      `/api/v1/clubs/${club}/members/${member}`
    )
  ).data.data;
  
  return data;
}

export async function clubMemberUpdateRoles({ club, member, form }: ClubMemberUpdateRolesInput) {
  const data = (
    await http.put<ApiResponse<ClubMemberRoles, null>>(
      `/api/v1/clubs/${club}/members/${member}/roles`, form
    )
  ).data.data;
  
  return data;
}

export async function clubMemberUpdateUserPassword({ club, member, form }: ClubMemberUpdateUserPasswordInput) {
  const data = (
    await http.put<ApiResponse<null, null>>(
      `/api/v1/clubs/${club}/members/${member}/user-password`, form
    )
  ).data.data;
  
  return data;
}

export async function clubMemberDelete({ club, member }: ClubMemberDeleteInput) {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/clubs/${club}/members/${member}`
    )
  ).data.data;
  
  return data;
}

export async function clubMemberInvite({ club, form }: ClubMemberInviteInput) {
  const data = (
    await http.post<ApiResponse<null, null>>(
      `/api/v1/clubs/${club}/members`, form
    )
  ).data.data;
  
  return data;
}

export async function clubOrderAttendantDownloadAgreement({ club, order, attendant }: ClubOrderAttendantDownloadAgreementInput) {
  const data = (
    await http.get<ApiResponse<null, null>>(
      `/api/v1/clubs/${club}/orders/${order}/attendants/${attendant}/agreement`
    )
  ).data.data;
  
  return data;
}

export async function clubOrderIndex({ club, params }: ClubOrderIndexInput) {
  const data = (
    await http.get<ApiResponse<ClubOrderListItem[], null>>(
      `/api/v1/clubs/${club}/orders`, { params }
    )
  ).data;
  
  return {...data, data: data.data.map(item => prepareClubOrderListItem(item))}
}

export async function clubOrderCountsByStatus({ club, params }: ClubOrderCountsByStatusInput) {
  const data = (
    await http.get<ApiResponse<ClubOrderCountsByStatus, null>>(
      `/api/v1/clubs/${club}/orders/counts-by-status`, { params }
    )
  ).data.data;
  
  return data;
}

export async function clubOrderShow({ club, order }: ClubOrderShowInput) {
  const data = (
    await http.get<ApiResponse<ClubOrderDetails, null>>(
      `/api/v1/clubs/${club}/orders/${order}`
    )
  ).data.data;
  
  return prepareClubOrderDetails(data)
}

export async function clubSettingsIndex({ club }: ClubSettingsIndexInput) {
  const data = (
    await http.get<ApiResponse<ClubSettings, null>>(
      `/api/v1/clubs/${club}/settings`
    )
  ).data.data;
  
  return data;
}

export async function clubSettingsUpdate({ club, form }: ClubSettingsUpdateInput) {
  const data = (
    await http.put<ApiResponse<ClubSettings, null>>(
      `/api/v1/clubs/${club}/settings`, form
    )
  ).data.data;
  
  return data;
}

export async function clubSettingsSessionAbsenceSettings({ club }: ClubSettingsSessionAbsenceSettingsInput) {
  const data = (
    await http.get<ApiResponse<ClubAbsenceSettings, null>>(
      `/api/v1/clubs/${club}/settings/session-absence`
    )
  ).data.data;
  
  return data;
}

export async function clubSettingsUpdateSessionAbsenceSettings({ club, form }: ClubSettingsUpdateSessionAbsenceSettingsInput) {
  const data = (
    await http.put<ApiResponse<ClubAbsenceSettings, null>>(
      `/api/v1/clubs/${club}/settings/session-absence`, form
    )
  ).data.data;
  
  return data;
}

export async function clubSurveyIndex({ club, params }: ClubSurveyIndexInput) {
  const data = (
    await http.get<ApiResponse<AttendantSurveyListView[], null>>(
      `/api/v1/clubs/${club}/surveys`, { params }
    )
  ).data.data;
  
  return data.map(item => prepareAttendantSurveyListView(item))
}

export async function clubSurveyDownload({ club, survey }: ClubSurveyDownloadInput) {
  const data = (
    await http.get<ApiResponse<null, null>>(
      `/api/v1/clubs/${club}/surveys/${survey}/xlsx`
    )
  ).data.data;
  
  return data;
}

export async function attendantRegistrationIndex() {
  const data = (
    await http.get<ApiResponse<MySessionRegistrationListItem[], null>>(
      `/api/v1/viewer/attendant-registrations`
    )
  ).data.data;
  
  return data.map(item => prepareMySessionRegistrationListItem(item))
}

export async function attendantRegistrationAgreement({ registration }: AttendantRegistrationAgreementInput) {
  const data = (
    await http.get<ApiResponse<null, null>>(
      `/api/v1/viewer/attendant-registrations/${registration}/agreement`
    )
  ).data.data;
  
  return data;
}

export async function attendantRegistrationShow({ registration }: AttendantRegistrationShowInput) {
  const data = (
    await http.get<ApiResponse<MySessionRegistrationDetails, null>>(
      `/api/v1/viewer/attendant-registrations/${registration}`
    )
  ).data.data;
  
  return prepareMySessionRegistrationDetails(data)
}

export async function attendantRegistrationSubmitAbsence({ registration, form }: AttendantRegistrationSubmitAbsenceInput) {
  const data = (
    await http.post<ApiResponse<SessionAbsenceDetails, null>>(
      `/api/v1/viewer/attendant-registrations/${registration}/absences`, prepareSubmitAbsenceForm(form)
    )
  ).data.data;
  
  return prepareSessionAbsenceDetails(data)
}

export async function attendantRegistrationUpdateAbsence({ registration, absence, form }: AttendantRegistrationUpdateAbsenceInput) {
  const data = (
    await http.put<ApiResponse<SessionAbsenceDetails, null>>(
      `/api/v1/viewer/attendant-registrations/${registration}/absences/${absence}`, prepareSubmitAbsenceForm(form)
    )
  ).data.data;
  
  return prepareSessionAbsenceDetails(data)
}

export async function adminPaymentIndex({ params }: AdminPaymentIndexInput) {
  const data = (
    await http.get<ApiResponse<AdminPaymentListView[], null>>(
      `/api/v1/admin/payments`, { params }
    )
  ).data;
  
  return {...data, data: data.data.map(item => prepareAdminPaymentListView(item))}
}

export async function adminPaymentMarkPaid({ payment }: AdminPaymentMarkPaidInput) {
  const data = (
    await http.get<ApiResponse<AdminPaymentListView, null>>(
      `/api/v1/admin/payments/${payment}`
    )
  ).data.data;
  
  return prepareAdminPaymentListView(data)
}

export async function attendantIndex() {
  const data = (
    await http.get<ApiResponse<AttendantListView[], null>>(
      `/api/v1/attendants`
    )
  ).data.data;
  
  return data;
}

export async function attendantShow({ attendant }: AttendantShowInput) {
  const data = (
    await http.get<ApiResponse<AttendantDetailsView, null>>(
      `/api/v1/attendants/${attendant}`
    )
  ).data.data;
  
  return prepareAttendantDetailsView(data)
}

export async function attendantUpdate({ attendant, form }: AttendantUpdateInput) {
  const data = (
    await http.put<ApiResponse<AttendantDetailsView, null>>(
      `/api/v1/attendants/${attendant}`, prepareAttendantForm(form)
    )
  ).data.data;
  
  return prepareAttendantDetailsView(data)
}

export async function attendantCreate({ form }: AttendantCreateInput) {
  const data = (
    await http.post<ApiResponse<AttendantListView, null>>(
      `/api/v1/attendants`, prepareAttendantForm(form)
    )
  ).data.data;
  
  return data;
}

export async function attendantDelete({ attendant }: AttendantDeleteInput) {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/attendants/${attendant}`
    )
  ).data.data;
  
  return data;
}

export async function authCreateToken({ form }: AuthCreateTokenInput) {
  const data = (
    await axios.post<ApiResponse<AccessToken, null>>(
      `/api/v1/auth/token`, form
    )
  ).data.data;
  
  return data;
}

export async function authRevoke() {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/auth/token`
    )
  ).data.data;
  
  return data;
}

export async function authRefreshToken() {
  const data = (
    await axios.get<ApiResponse<AccessToken, null>>(
      `/api/v1/auth/token/fresh`
    )
  ).data.data;
  
  return data;
}

export async function authMe() {
  const data = (
    await http.get<ApiResponse<UserDetailsView, null>>(
      `/api/v1/auth/me`
    )
  ).data.data;
  
  return data;
}

export async function authUpdateMe({ form }: AuthUpdateMeInput) {
  const data = (
    await http.put<ApiResponse<UserDetailsView, null>>(
      `/api/v1/auth/me`, form
    )
  ).data.data;
  
  return data;
}

export async function authStartRecovery({ form }: AuthStartRecoveryInput) {
  const data = (
    await axios.post<ApiResponse<null, null>>(
      `/api/v1/auth/recovery`, form
    )
  ).data.data;
  
  return data;
}

export async function authCheckRecovery({ token }: AuthCheckRecoveryInput) {
  const data = (
    await axios.get<ApiResponse<RecoveryStatus, null>>(
      `/api/v1/auth/recovery/${token}`
    )
  ).data.data;
  
  return data;
}

export async function authChangePassword({ form }: AuthChangePasswordInput) {
  const data = (
    await axios.post<ApiResponse<null, null>>(
      `/api/v1/auth/password`, form
    )
  ).data.data;
  
  return data;
}

export async function categoryIndex() {
  const data = (
    await http.get<ApiResponse<CategoryListView[], null>>(
      `/api/v1/categories`
    )
  ).data.data;
  
  return data;
}

export async function categoryUsed() {
  const data = (
    await http.get<ApiResponse<CategoryListView[], null>>(
      `/api/v1/categories/used`
    )
  ).data.data;
  
  return data;
}

export async function categoryShow({ category }: CategoryShowInput) {
  const data = (
    await http.get<ApiResponse<CategoryDetailsView, null>>(
      `/api/v1/categories/${category}`
    )
  ).data.data;
  
  return data;
}

export async function categoryCreate({ form, files }: CategoryCreateInput) {
  const data = (
    await http.post<ApiResponse<CategoryListView, null>>(
      `/api/v1/categories`, makeFormData(form, files), { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  ).data.data;
  
  return data;
}

export async function categoryUpdate({ category, form, files }: CategoryUpdateInput) {
  const data = (
    await http.put<ApiResponse<CategoryDetailsView, null>>(
      `/api/v1/categories/${category}`, makeFormData(form, files), { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  ).data.data;
  
  return data;
}

export async function categoryDelete({ category }: CategoryDeleteInput) {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/categories/${category}`
    )
  ).data.data;
  
  return data;
}

export async function cityIndex() {
  const data = (
    await http.get<ApiResponse<CityListView[], null>>(
      `/api/v1/cities`
    )
  ).data.data;
  
  return data;
}

export async function invitationIndex() {
  const data = (
    await http.get<ApiResponse<AttendantInvitationListView[], null>>(
      `/api/v1/invitations`
    )
  ).data.data;
  
  return data.map(item => prepareAttendantInvitationListView(item))
}

export async function invitationAccept({ invitation, form }: InvitationAcceptInput) {
  const data = (
    await http.post<ApiResponse<AcceptedInvitationsResponse, null>>(
      `/api/v1/invitations/${invitation}/acceptance`, form
    )
  ).data.data;
  
  return data;
}

export async function invitationDelete({ invitation }: InvitationDeleteInput) {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/invitations/${invitation}`
    )
  ).data.data;
  
  return data;
}

export async function invoiceMyInvoices() {
  const data = (
    await http.get<ApiResponse<ParentInvoiceListView[], null>>(
      `/api/v1/invoices/my-invoices`
    )
  ).data.data;
  
  return data.map(item => prepareParentInvoiceListView(item))
}

export async function invoiceShow({ token }: InvoiceShowInput) {
  const data = (
    await axios.get<ApiResponse<ParentInvoiceListView, null>>(
      `/api/v1/invoices/${token}`
    )
  ).data.data;
  
  return prepareParentInvoiceListView(data)
}

export async function invoiceStartTokenPayment({ token }: InvoiceStartTokenPaymentInput) {
  const data = (
    await axios.post<ApiResponse<InitiatePaymentResponse, null>>(
      `/api/v1/invoices/${token}/token-payment`, null
    )
  ).data.data;
  
  return data;
}

export async function invoiceStartPayment({ invoice }: InvoiceStartPaymentInput) {
  const data = (
    await http.post<ApiResponse<InitiatePaymentResponse, null>>(
      `/api/v1/invoices/${invoice}/payment`, null
    )
  ).data.data;
  
  return data;
}

export async function invoiceDownloadPdf({ invoice }: InvoiceDownloadPdfInput) {
  const data = (
    await http.get<ApiResponse<null, null>>(
      `/api/v1/invoices/${invoice}/pdf`
    )
  ).data.data;
  
  return data;
}

export async function memberInvitationShow({ token }: MemberInvitationShowInput) {
  const data = (
    await axios.get<ApiResponse<MemberInvitationDetails, null>>(
      `/api/v1/member-invitations/${token}`
    )
  ).data.data;
  
  return data;
}

export async function memberInvitationAccept({ form, token }: MemberInvitationAcceptInput) {
  const data = (
    await axios.post<ApiResponse<AcceptMemberInvitationResult, null>>(
      `/api/v1/member-invitations/${token}`, form
    )
  ).data.data;
  
  return data;
}

export async function menuHomePageSettings() {
  const data = (
    await http.get<ApiResponse<HomePageSettings, null>>(
      `/api/v1/menus/home`
    )
  ).data.data;
  
  return data;
}

export async function menuUpdateHomePageSettings({ form }: MenuUpdateHomePageSettingsInput) {
  const data = (
    await http.put<ApiResponse<HomePageSettings, null>>(
      `/api/v1/menus/home`, form
    )
  ).data.data;
  
  return data;
}

export async function menuShow({ menuKey }: MenuShowInput) {
  const data = (
    await http.get<ApiResponse<MenuDetails, null>>(
      `/api/v1/menus/${menuKey}`
    )
  ).data.data;
  
  return data;
}

export async function menuUpdateSorting({ menuKey, form }: MenuUpdateSortingInput) {
  const data = (
    await http.put<ApiResponse<MenuDetails, null>>(
      `/api/v1/menus/${menuKey}/sort`, form
    )
  ).data.data;
  
  return data;
}

export async function menuShowItem({ menuKey, item }: MenuShowItemInput) {
  const data = (
    await http.get<ApiResponse<MenuItemDetails, null>>(
      `/api/v1/menus/${menuKey}/items/${item}`
    )
  ).data.data;
  
  return data;
}

export async function menuUpdateItem({ menuKey, item, form }: MenuUpdateItemInput) {
  const data = (
    await http.put<ApiResponse<MenuItemDetails, null>>(
      `/api/v1/menus/${menuKey}/items/${item}`, form
    )
  ).data.data;
  
  return data;
}

export async function menuCreateItem({ menuKey, form }: MenuCreateItemInput) {
  const data = (
    await http.post<ApiResponse<MenuItemListView, null>>(
      `/api/v1/menus/${menuKey}/items`, form
    )
  ).data.data;
  
  return data;
}

export async function menuDeleteItem({ menuKey, item }: MenuDeleteItemInput) {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/menus/${menuKey}/items/${item}`
    )
  ).data.data;
  
  return data;
}

export async function messageIndex() {
  const data = (
    await http.get<ApiResponse<MessageListView[], null>>(
      `/api/v1/messages`
    )
  ).data.data;
  
  return data.map(item => prepareMessageListView(item))
}

export async function messageShow({ message }: MessageShowInput) {
  const data = (
    await http.get<ApiResponse<MessageDetailsView, null>>(
      `/api/v1/messages/${message}`
    )
  ).data.data;
  
  return prepareMessageDetailsView(data)
}

export async function pageIndex() {
  const data = (
    await http.get<ApiResponse<PageListView[], null>>(
      `/api/v1/pages`
    )
  ).data.data;
  
  return data;
}

export async function pageShow({ page }: PageShowInput) {
  const data = (
    await http.get<ApiResponse<PageDetails, null>>(
      `/api/v1/pages/${page}`
    )
  ).data.data;
  
  return data;
}

export async function pageCreate({ form }: PageCreateInput) {
  const data = (
    await http.post<ApiResponse<PageListView, null>>(
      `/api/v1/pages`, form
    )
  ).data.data;
  
  return data;
}

export async function pageUpdate({ page, form }: PageUpdateInput) {
  const data = (
    await http.put<ApiResponse<PageDetails, null>>(
      `/api/v1/pages/${page}`, form
    )
  ).data.data;
  
  return data;
}

export async function pageDelete({ page }: PageDeleteInput) {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/pages/${page}`
    )
  ).data.data;
  
  return data;
}

export async function paymentConfirm({ form }: PaymentConfirmInput) {
  const data = (
    await axios.post<ApiResponse<ParentInvoiceListView, null>>(
      `/api/v1/payments/confirmation`, form
    )
  ).data.data;
  
  return prepareParentInvoiceListView(data)
}

export async function paymentCancel({ form }: PaymentCancelInput) {
  const data = (
    await axios.post<ApiResponse<ParentInvoiceListView, null>>(
      `/api/v1/payments/cancellation`, form
    )
  ).data.data;
  
  return prepareParentInvoiceListView(data)
}

export async function publicHolidayIndex() {
  const data = (
    await http.get<ApiResponse<PublicHolidayView[], null>>(
      `/api/v1/public-holidays`
    )
  ).data.data;
  
  return data.map(item => preparePublicHolidayView(item))
}

export async function publicHolidayCreate({ form }: PublicHolidayCreateInput) {
  const data = (
    await http.post<ApiResponse<PublicHolidayView, null>>(
      `/api/v1/public-holidays`, prepareCreatePublicHoliday(form)
    )
  ).data.data;
  
  return preparePublicHolidayView(data)
}

export async function publicHolidayDelete({ holiday }: PublicHolidayDeleteInput) {
  const data = (
    await http.delete<ApiResponse<null, null>>(
      `/api/v1/public-holidays/${holiday}`
    )
  ).data.data;
  
  return data;
}

export async function scheduleDaySchedule({ date }: ScheduleDayScheduleInput) {
  const data = (
    await http.get<ApiResponse<DaySchedule, null>>(
      `/api/v1/schedule/day-schedules/${format(date, DATE_FORMAT)}`
    )
  ).data.data;
  
  return data;
}

export async function schedulePeriodSummary({ start, end }: SchedulePeriodSummaryInput) {
  const data = (
    await http.get<ApiResponse<PeriodSummaryView[], null>>(
      `/api/v1/schedule/period-summaries/${format(start, DATE_FORMAT)}/${format(end, DATE_FORMAT)}`
    )
  ).data.data;
  
  return data.map(item => preparePeriodSummaryView(item))
}

export async function sessionIndex({ params }: SessionIndexInput) {
  const data = (
    await http.get<ApiResponse<SessionListView[], null>>(
      `/api/v1/sessions`, { params }
    )
  ).data.data;
  
  return data;
}

export async function sessionShow({ session }: SessionShowInput) {
  const data = (
    await http.get<ApiResponse<ParentSessionDetailsView, null>>(
      `/api/v1/sessions/${session}`
    )
  ).data.data;
  
  return prepareParentSessionDetailsView(data)
}

export async function surveyCreate({ form }: SurveyCreateInput) {
  const data = (
    await http.post<ApiResponse<AttendantSurveyDetails, null>>(
      `/api/v1/surveys`, form
    )
  ).data.data;
  
  return prepareAttendantSurveyDetails(data)
}

function prepareCoachActivityListView({ shiftsStart, shiftsEnd, ...rest }: CoachActivityListView): CoachActivityListView {
  return {
    ...rest,
    shiftsStart: shiftsStart && new Date(shiftsStart),
    shiftsEnd: shiftsEnd && new Date(shiftsEnd),

  };
}

function prepareCoachShiftListView({ sessions, ...rest }: CoachShiftListView): CoachShiftListView {
  return {
    ...rest,
    sessions: sessions.map(item => prepareCoachSessionListView(item)),

  };
}

function prepareCoachSessionListView({ shift, ...rest }: CoachSessionListView): CoachSessionListView {
  return {
    ...rest,
    shift: prepareShiftView(shift),

  };
}

function prepareShiftView({ start, end, ...rest }: ShiftView): ShiftView {
  return {
    ...rest,
    start: new Date(start),
    end: end && new Date(end),

  };
}

function prepareCoachSessionDetailsView({ prices, shift, ...rest }: CoachSessionDetailsView): CoachSessionDetailsView {
  return {
    ...rest,
    prices: prices.map(item => prepareSessionPriceView(item)),
    shift: prepareShiftView(shift),

  };
}

function prepareSessionPriceView({ validUntil, ...rest }: SessionPriceView): SessionPriceView {
  return {
    ...rest,
    validUntil: new Date(validUntil),

  };
}

function prepareAddSessionForm({ prices, ...rest }: AddSessionForm): AddSessionForm {
  return {
    ...rest,
    prices: prices.map(item => prepareSessionPriceForm(item)),

  };
}

function prepareSessionPriceForm({ validUntil, ...rest }: SessionPriceForm): SessionPriceForm {
  return {
    ...rest,
    validUntil: format(validUntil, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") as unknown as Date,

  };
}

function prepareUpdateSessionForm({ prices, ...rest }: UpdateSessionForm): UpdateSessionForm {
  return {
    ...rest,
    prices: prices.map(item => prepareSessionPriceForm(item)),

  };
}

function prepareCoachSessionInvitationListView({ created, ...rest }: CoachSessionInvitationListView): CoachSessionInvitationListView {
  return {
    ...rest,
    created: new Date(created),

  };
}

function prepareAttendantInvitationDetailsView({ created, session, ...rest }: AttendantInvitationDetailsView): AttendantInvitationDetailsView {
  return {
    ...rest,
    created: new Date(created),
    session: prepareInvitationSessionView(session),

  };
}

function prepareInvitationSessionView({ shift, ...rest }: InvitationSessionView): InvitationSessionView {
  return {
    ...rest,
    shift: prepareParentShiftView(shift),

  };
}

function prepareParentShiftView({ start, end, ...rest }: ParentShiftView): ParentShiftView {
  return {
    ...rest,
    start: new Date(start),
    end: end && new Date(end),

  };
}

function prepareCoachAttendantRegistrationListView({ registrationDate, firstSessionDate, ...rest }: CoachAttendantRegistrationListView): CoachAttendantRegistrationListView {
  return {
    ...rest,
    registrationDate: new Date(registrationDate),
    firstSessionDate: firstSessionDate && new Date(firstSessionDate),

  };
}

function prepareCoachAttendantRegistrationDetails({ startDate, attendant, results, ...rest }: CoachAttendantRegistrationDetails): CoachAttendantRegistrationDetails {
  return {
    ...rest,
    startDate: new Date(startDate),
    attendant: prepareCoachRegistrationAttendantDetails(attendant),
    results: results.map(item => prepareSessionResultView(item)),

  };
}

function prepareCoachRegistrationAttendantDetails({ dateOfBirth, ...rest }: CoachRegistrationAttendantDetails): CoachRegistrationAttendantDetails {
  return {
    ...rest,
    dateOfBirth: new Date(dateOfBirth),

  };
}

function prepareSessionResultView({ created, ...rest }: SessionResultView): SessionResultView {
  return {
    ...rest,
    created: new Date(created),

  };
}

function prepareCoachSessionReservationListView({ created, ...rest }: CoachSessionReservationListView): CoachSessionReservationListView {
  return {
    ...rest,
    created: new Date(created),

  };
}

function prepareShiftForm({ start, end, ...rest }: ShiftForm): ShiftForm {
  return {
    ...rest,
    start: format(start, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") as unknown as Date,
    end: end && format(end, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") as unknown as Date,

  };
}

function prepareAdminSessionAbsenceListItem({ start, end, createdAt, ...rest }: AdminSessionAbsenceListItem): AdminSessionAbsenceListItem {
  return {
    ...rest,
    start: new Date(start),
    end: new Date(end),
    createdAt: new Date(createdAt),

  };
}

function prepareClubAttendantListView({ createdAt, ...rest }: ClubAttendantListView): ClubAttendantListView {
  return {
    ...rest,
    createdAt: createdAt && new Date(createdAt),

  };
}

function prepareClubAttendantDetails({ dateOfBirth, createdAt, ...rest }: ClubAttendantDetails): ClubAttendantDetails {
  return {
    ...rest,
    dateOfBirth: new Date(dateOfBirth),
    createdAt: createdAt && new Date(createdAt),

  };
}

function prepareAdminAttendantForm({ dateOfBirth, ...rest }: AdminAttendantForm): AdminAttendantForm {
  return {
    ...rest,
    dateOfBirth: format(dateOfBirth, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") as unknown as Date,

  };
}

function prepareClubAttendantRegistrationListView({ startDate, ...rest }: ClubAttendantRegistrationListView): ClubAttendantRegistrationListView {
  return {
    ...rest,
    startDate: new Date(startDate),

  };
}

function prepareDiscountListView({ createdAt, expiresAt, ...rest }: DiscountListView): DiscountListView {
  return {
    ...rest,
    createdAt: new Date(createdAt),
    expiresAt: expiresAt && new Date(expiresAt),

  };
}

function prepareDiscountDetails({ validFrom, expiresAt, history, ...rest }: DiscountDetails): DiscountDetails {
  return {
    ...rest,
    validFrom: validFrom && new Date(validFrom),
    expiresAt: expiresAt && new Date(expiresAt),
    history: history.map(item => prepareDiscountHistory(item)),

  };
}

function prepareDiscountHistory({ usedAt, ...rest }: DiscountHistory): DiscountHistory {
  return {
    ...rest,
    usedAt: new Date(usedAt),

  };
}

function prepareDiscountForm({ validFrom, expiresAt, ...rest }: DiscountForm): DiscountForm {
  return {
    ...rest,
    validFrom: validFrom && format(validFrom, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") as unknown as Date,
    expiresAt: expiresAt && format(expiresAt, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") as unknown as Date,

  };
}

function prepareClubInvoiceListView({ createdDate, sentAt, payment, ...rest }: ClubInvoiceListView): ClubInvoiceListView {
  return {
    ...rest,
    createdDate: new Date(createdDate),
    sentAt: sentAt && new Date(sentAt),
    payment: payment && preparePaymentListView(payment),

  };
}

function preparePaymentListView({ paidAt, ...rest }: PaymentListView): PaymentListView {
  return {
    ...rest,
    paidAt: paidAt && new Date(paidAt),

  };
}

function prepareClubInvoiceView({ paymentDate, createdDate, sentAt, payments, ...rest }: ClubInvoiceView): ClubInvoiceView {
  return {
    ...rest,
    paymentDate: new Date(paymentDate),
    createdDate: new Date(createdDate),
    sentAt: sentAt && new Date(sentAt),
    payments: payments.map(item => prepareClubInvoiceViewPayment(item)),

  };
}

function prepareClubInvoiceViewPayment({ paidAt, ...rest }: ClubInvoiceViewPayment): ClubInvoiceViewPayment {
  return {
    ...rest,
    paidAt: paidAt && new Date(paidAt),

  };
}

function prepareClubInvoiceSync({ lastAttemptAt, messages, ...rest }: ClubInvoiceSync): ClubInvoiceSync {
  return {
    ...rest,
    lastAttemptAt: lastAttemptAt && new Date(lastAttemptAt),
    messages: messages.map(item => prepareHttpMessageView(item)),

  };
}

function prepareHttpMessageView({ sentAt, ...rest }: HttpMessageView): HttpMessageView {
  return {
    ...rest,
    sentAt: new Date(sentAt),

  };
}

function prepareClubInvoiceViewPaymentDetails({ paidAt, ...rest }: ClubInvoiceViewPaymentDetails): ClubInvoiceViewPaymentDetails {
  return {
    ...rest,
    paidAt: paidAt && new Date(paidAt),

  };
}

function prepareClubOrderListItem({ receivedAt, payment, ...rest }: ClubOrderListItem): ClubOrderListItem {
  return {
    ...rest,
    receivedAt: receivedAt && new Date(receivedAt),
    payment: payment && prepareClubOrderPaymentListItem(payment),

  };
}

function prepareClubOrderPaymentListItem({ paidAt, ...rest }: ClubOrderPaymentListItem): ClubOrderPaymentListItem {
  return {
    ...rest,
    paidAt: paidAt && new Date(paidAt),

  };
}

function prepareClubOrderDetails({ receivedAt, payment, attendants, ...rest }: ClubOrderDetails): ClubOrderDetails {
  return {
    ...rest,
    receivedAt: receivedAt && new Date(receivedAt),
    payment: payment && prepareClubOrderPayment(payment),
    attendants: attendants.map(item => prepareClubOrderAttendant(item)),

  };
}

function prepareClubOrderPayment({ paidAt, ...rest }: ClubOrderPayment): ClubOrderPayment {
  return {
    ...rest,
    paidAt: paidAt && new Date(paidAt),

  };
}

function prepareClubOrderAttendant({ dateOfBirth, ...rest }: ClubOrderAttendant): ClubOrderAttendant {
  return {
    ...rest,
    dateOfBirth: new Date(dateOfBirth),

  };
}

function prepareAttendantSurveyListView({ createdAt, ...rest }: AttendantSurveyListView): AttendantSurveyListView {
  return {
    ...rest,
    createdAt: new Date(createdAt),

  };
}

function prepareMySessionRegistrationListItem({ session, ...rest }: MySessionRegistrationListItem): MySessionRegistrationListItem {
  return {
    ...rest,
    session: prepareMySessionListItem(session),

  };
}

function prepareMySessionListItem({ shift, ...rest }: MySessionListItem): MySessionListItem {
  return {
    ...rest,
    shift: prepareParentShiftView(shift),

  };
}

function prepareMySessionRegistrationDetails({ startDate, session, results, absences, absenceMinDate, absenceMaxDate, ...rest }: MySessionRegistrationDetails): MySessionRegistrationDetails {
  return {
    ...rest,
    startDate: new Date(startDate),
    session: prepareMySessionRegistrationSession(session),
    results: results.map(item => prepareSessionResultView(item)),
    absences: absences.map(item => prepareSessionAbsenceDetails(item)),
    absenceMinDate: absenceMinDate && new Date(absenceMinDate),
    absenceMaxDate: new Date(absenceMaxDate),

  };
}

function prepareMySessionRegistrationSession({ shift, ...rest }: MySessionRegistrationSession): MySessionRegistrationSession {
  return {
    ...rest,
    shift: prepareParentShiftView(shift),

  };
}

function prepareSessionAbsenceDetails({ start, end, ...rest }: SessionAbsenceDetails): SessionAbsenceDetails {
  return {
    ...rest,
    start: new Date(start),
    end: new Date(end),

  };
}

function prepareSubmitAbsenceForm({ start, end, ...rest }: SubmitAbsenceForm): SubmitAbsenceForm {
  return {
    ...rest,
    start: format(start, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") as unknown as Date,
    end: format(end, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") as unknown as Date,

  };
}

function prepareAdminPaymentListView({ paidAt, order, ...rest }: AdminPaymentListView): AdminPaymentListView {
  return {
    ...rest,
    paidAt: paidAt && new Date(paidAt),
    order: order && prepareAdminPaymentListOrder(order),

  };
}

function prepareAdminPaymentListOrder({ receivedAt, ...rest }: AdminPaymentListOrder): AdminPaymentListOrder {
  return {
    ...rest,
    receivedAt: new Date(receivedAt),

  };
}

function prepareAttendantDetailsView({ dateOfBirth, ...rest }: AttendantDetailsView): AttendantDetailsView {
  return {
    ...rest,
    dateOfBirth: new Date(dateOfBirth),

  };
}

function prepareAttendantForm({ dateOfBirth, ...rest }: AttendantForm): AttendantForm {
  return {
    ...rest,
    dateOfBirth: format(dateOfBirth, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") as unknown as Date,

  };
}

function prepareAttendantInvitationListView({ created, session, ...rest }: AttendantInvitationListView): AttendantInvitationListView {
  return {
    ...rest,
    created: new Date(created),
    session: prepareInvitationSessionView(session),

  };
}

function prepareParentInvoiceListView({ paymentDate, ...rest }: ParentInvoiceListView): ParentInvoiceListView {
  return {
    ...rest,
    paymentDate: new Date(paymentDate),

  };
}

function prepareMessageListView({ sent, ...rest }: MessageListView): MessageListView {
  return {
    ...rest,
    sent: new Date(sent),

  };
}

function prepareMessageDetailsView({ sent, ...rest }: MessageDetailsView): MessageDetailsView {
  return {
    ...rest,
    sent: new Date(sent),

  };
}

function preparePublicHolidayView({ date, ...rest }: PublicHolidayView): PublicHolidayView {
  return {
    ...rest,
    date: new Date(date),

  };
}

function prepareCreatePublicHoliday({ date, ...rest }: CreatePublicHoliday): CreatePublicHoliday {
  return {
    ...rest,
    date: format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") as unknown as Date,

  };
}

function preparePeriodSummaryView({ date, ...rest }: PeriodSummaryView): PeriodSummaryView {
  return {
    ...rest,
    date: new Date(date),

  };
}

function prepareParentSessionDetailsView({ shift, ...rest }: ParentSessionDetailsView): ParentSessionDetailsView {
  return {
    ...rest,
    shift: prepareParentShiftView(shift),

  };
}

function prepareAttendantSurveyDetails({ createdAt, ...rest }: AttendantSurveyDetails): AttendantSurveyDetails {
  return {
    ...rest,
    createdAt: new Date(createdAt),

  };
}
