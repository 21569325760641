import { useCallback, useState } from 'react';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import InvoiceRowDetails from 'components/InvoiceRowDetails';
import EuroPrice from 'components/EuroPrice';
import InvoiceRowPaymentButton from 'components/InvoiceRowPaymentButton';

import { ParentInvoiceListView } from 'schema';
import { format } from 'date-fns/format';
import { locale } from 'const';

type Props = {
  invoice: ParentInvoiceListView;
  defaultOpen?: boolean;
  tokenPayment?: boolean;
};

const InvoiceRowWithDetails = (props: Props) => {
  const { invoice, defaultOpen = false, tokenPayment } = props;

  const [open, setOpen] = useState(
    window.location.hash.replace('#', '') === invoice.invoiceNumber ||
      defaultOpen
  );

  const handleClick = useCallback(() => {
    setOpen(v => !v);
  }, []);

  return (
    <>
      <TableRow onClick={handleClick}>
        <TableCell sx={[open && { borderBottom: 0 }]}>
          {format(new Date(invoice.period), "yyyy 'm.' LLLL", { locale })}
        </TableCell>

        <TableCell sx={[open && { borderBottom: 0 }]}>
          <EuroPrice>{invoice.total}</EuroPrice>
        </TableCell>

        <TableCell sx={[{ textAlign: 'right' }, open && { borderBottom: 0 }]}>
          <InvoiceRowPaymentButton
            invoice={invoice}
            tokenPayment={tokenPayment}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <InvoiceRowDetails
            open={open}
            invoice={invoice}
            tokenPayment={tokenPayment}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default InvoiceRowWithDetails;
