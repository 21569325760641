import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InvoiceRowWithDetails from 'components/InvoiceRowWithDetails';
import InvoiceTable from 'components/InvoiceTable';
import PaymentStatus from 'components/PaymentStatus';

import { ParentInvoiceListView } from 'schema';

type Props = {
  invoice: ParentInvoiceListView;
};

const InvoiceDetails = ({ invoice }: Props) => (
  <Box>
    <Box sx={{ p: 2 }}>
      <Typography variant="h6">Atsiskaitymas už paslaugas</Typography>

      <PaymentStatus />
    </Box>

    <InvoiceTable>
      <InvoiceRowWithDetails defaultOpen tokenPayment invoice={invoice} />
    </InvoiceTable>
  </Box>
);

export default InvoiceDetails;
